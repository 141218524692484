html[dir='rtl']{

	.header{
		.content{
			@include md {
				padding-left: 0;
				padding-right: 32px;
			}
		}

		.company-logo{
			@include md {
				margin-right: 30px;
				margin-left: 0;
			}
		}
	}


	.user {
		&-avatar {
			&--image{
				margin-right: 0;
				margin-left: 20px;
				@include break-max(480px){
					margin-right: 0;
					margin-left: 8px;
				}

				&:hover{
					transform: scale(2.8) translate(-30%, 7%);
					@include break-max($mob){
						transform: scale(2.8) translate(-25%, 32%);
					}
				}
			}
		}
	}


	ul.event-list .event-list-item--descr-trigger{
		right: auto;
		left: -45px;
	}

	.event-heading {
		padding-right: 0;
		padding-left: 25px;
	}

	.#{$fa-css-prefix}-chevron-left:before { content: fa-content($fa-var-chevron-right); }
	.#{$fa-css-prefix}-chevron-right:before {  content: fa-content($fa-var-chevron-left);}

	.event-block--step-form .step-title,
	.event-block--step-hours .step-title {
		text-align: right;
	}

	.time-table {
		padding-left: 0;
		padding-right: 54px;
	}
	.time-table__time-divider {
		left: unset;
		right: -52px;
	}
	.time-table__time-divider hr {
		position: absolute;
		top: 50%;
		left: -42px;
		right: auto;
	}

	.sidebar.event-block--step-item__sidebar{
		@include md {
			margin-right: 0;
			margin-left: 40px;
		}
	}

	.react-select__menu {
		text-align: right;
	}

	.react-select__dropdown-indicator {
		padding: 0 0px 0 6px !important;
	}
	.react-select__value-container {
		padding-left: 0 !important;
		padding-right: 8px !important;
	}

	.event-header .react-select__single-value,
	.event-header .react-select__dropdown-indicator,
	.locked-timezone {
		direction: rtl;
		text-align: right;
	}

	.multiple-meeting--slots-list__item {
		padding: 4px 20px 4px 28px;
	}
	.multiple-meeting--slots-list__item-remove {
		left: 0;
		right: auto;
	}
	.scheduled.scheduled--with-multiple-slots .multiple-meeting--slots-list__item {
		padding: 4px 8px 4px 24px;
	}

	.form-group .one-row input[type="checkbox"] {
		left: auto;
		right: 0;
	}
	.form-group .one-row .custom-checkbox {
		margin-left: 12px;
		margin-right: 0;
	}

	.details-list__item--name .details-list__item-col--value .service-color {
		float: right;
		margin-left: 8px;
		margin-right: 0;
	}

	.intl-tel-input.allow-dropdown.separate-dial-code {
		.selected-dial-code {
			padding-right: 28px;
			padding-left: 10px;
			direction: ltr;
		}
		.flag-container {
			.selected-flag {
				.iti-flag {
					right: 6px;
				}
			}
			.arrow {
				position: absolute;
				top: 36%;
				right: auto;
				left: 6px;
				margin: 0;
			}
		}
		.country-list {
			text-align: right;

			.flag-box {
				margin-left: 6px;
				margin-right: 0;
			}
		}
		input {
			padding-left: 0;
			padding-right: 92px;
		}
	}

	.tag-item__color {
		margin-left: 6px;
		margin-right: 0;
	}

	.back-button__to-organization .ico svg {
		transform: scale(-1, 1);
	}
	.status{
		gap: 8px;
	}
}