.event-list{
    &--tab-nav{
        text-align: center;
        margin-bottom: 30px;
    }
    &--tab-item{
        display: none;

        &.tab-active{
            display: block;
        }
    }
}

.tab-nav{
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-flex;
    align-items: stretch;
    border-radius: 5px;
    line-height: 1;
    border: 1px solid #eee;
    @include break-max($mob){
        flex-direction: column;
        margin-bottom: 22px;
        width: 100%;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }

    &--item{
        list-style: none;
        line-height: 1;
    }

    &--link{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 45px;
        border-radius: 5px;
        padding: 5px 25px;
        color: $main;
        opacity: 0.7;
        cursor: pointer;
        transition: all 0.2s ease-out;
        min-width: 280px;
        white-space: nowrap;
        &:hover{
            color: $main;
            background: rgba(#fff, 0.8);
        }

        &.tab-active{
            background: rgba(#fff, 0.9);
            opacity: 1;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        }
    }
}