.cookies{
	position: fixed;
	bottom: 92px;
	left: 0;
	right: 0;
	margin: 0 auto;
	padding: 25px 35px;
	width: 100%;
	max-width: 980px;
	background: #FFFFFF;
	box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
	border-radius: 8px;
	z-index: 1000;

	@include break-max(980px){
		border-radius: 0;
	}
	@include break-max(600px){
		bottom: 132px;
		padding-left: 22px;
		padding-right: 22px;
	}

	&__card {
		display: flex;
		align-items: center;

		@include break-max($mob){
			flex-direction: column;
			text-align: center;
		}
	}

	&__card-data {
		display: flex;
		align-items: center;
		gap: 10px 30px;

		@include break-max($mob){
			text-align: center;
			margin: 0 auto;
			justify-content: center;
			flex-direction: column;
		}
	}

	&__card-image {
		min-width: 54px;
		width: 54px;
		position: relative;
		min-height: 54px;
		margin-right: 30px;
		align-self: center;

		@include break-max($mob) {
			display: none;
		}
	}

	&__card-title {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 22px;
		line-height: 1;

		@include break-max($mob) {
			justify-content: center;
		}
	}

	&__card-text {
		@include break-max($mob) {
			max-width: 600px;
			text-align: center;
		}
	}

	&__card-btns {
		padding-top: 10px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__card-btn {
		min-width: 100px;
		justify-content: center;
		font-weight: 600;
		padding-left: 10px;
		padding-right: 10px;
		margin: 0 !important;

		&.btn--alt{
			background: #FEC439;
			color: $color-dark;
		}
		&.btn--white{
			color: $color-dark;
			background: $second-brand-color;
		}

		@include break-max($mob) {
			min-width: 50px;
		}
	}
}


.same-site-cookies{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($main, 0.2);
	z-index: 99999;
	padding: 22px;

	&.--visible{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__popup{
		width: 94%;
		max-width: 420px;
		margin: 10px auto;
		text-align: center;
		padding: 30px 22px;
		background: #fff;
		border-radius: 10px;
		color: $main;
		box-shadow: 0px 0px 22px -4px rgba($main, 0.2);

		.btn{
			min-width: 160px;
			background: $color-green;
			border: none;
			color: #fff;
		}
	}
	&__popup-title{
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 12px;
	}
	&__popup-text{
		font-size: 14px;
		margin-bottom: 22px;
	}
	&__popup-btn{
		min-width: 160px;
	}
	&__popup-btn-bar{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 10px;

		.btn--link{
			display: inline-block;
			padding: 4px 0;
		}
	}
}