body{
	padding-bottom: 102px;
	@include break-max(600px){
		//padding-bottom: 128px;
		padding-bottom: 0;
	}
}

.booking-page__main-wrapper{
	width: 100%;
}
.booking-page__powered-by-sb{
	position: relative;
	width: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	margin-bottom: 0;
	margin-top: auto;
	padding: 20px 0;
	border-top: 1px solid $border-color;
	z-index: 100;

	&.--fixed{
		position: fixed;
		@include break-max(600px){
			position: relative;
		}
	}

	.wrapper{
		display: block;
		padding: 0 20px;
	}
	.powered-by-sb{
		&__row{
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 40px;
			line-height: 1;
			gap: 5px;
		}
		&__item{
			p{
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: $color-grey;
				line-height: 1;
				gap: 5px;
				@include break-max(600px){
					//flex-direction: column;
					font-size: 11px;
				}

				span{
					display: inline-block;
					padding: 8px 25px;
					line-height: 1;
					@include break-max(600px){
						padding:  0;
						text-align: center;
						min-width: 70px;
					}
				}
				a{
					height: 28px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					line-height: 1;
					overflow: hidden;
					svg{
						height: 22px;
					}
					@include break-max(600px){
						svg{
							height: 19px;
						}
					}
				}
				.powered-by__logo-sm{
					svg{
						height: 40px;
					}
				}
			}

			.btn-bar{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -12px;
				margin-right: -12px;

				a{
					display: inline-block;
					text-align: center;
					padding: 0 4px;
					margin: 0 12px;
					color: #ABBCCC;
					line-height: 1;
					svg{
						color: #ABBCCC;
						height: 18px;
					}
				}
			}
		}
	}
}