.body--3{
	&.directory-theme{
		background: #fff !important;
		color: $directory-black-color !important;

		.back-to-bookingpage{
			width: 100%;
			max-width: 980px;
			margin: 5px auto;
			padding: 0 20px;

			.btn.back-button{
				position: sticky;
				top: 20px;
				left: 0;
				background: $directory-base-color !important;
				color: $directory-black-color !important;
				z-index: 111;
				font-weight: 400;

				&:hover, &:active, &:focus{
					background: darken($directory-base-color, 8%) !important;
					color: $directory-black-color !important;
				}

			}
		}

		.step-title,
		ul.event-list .event-list-item .ico,
		ul.event-list .event-list-item--body,
		ul.event-list .event-list-item--title,
		ul.event-list a,
		ul.event-list a li.event-list-item span p,
		ul.event-list li.event-list-item:hover,
		ul.event-list .event-list-item--additional-info .event-flexible-duration .react-select__control .react-select__single-value{
			color: $color-neutral-dark;
		}


		ul.event-list .event-list-item--descr-trigger,
		.event-description--wrapper .event-description a,
		.rich-text a,
		ul.event-list .event-list-item .collapse .event-list-item--content > * a,
		ul.event-list .event-list-item .collapse .event-list-item--content a,
		.event-list-item--content--full a, .event-list-item--content--short a{
			color: $directory-base-color !important;

			&:hover, &:focus{
				color: darken($directory-base-color, 8%) !important;
			}
		}

		ul.event-list .event-list-item .collapse .event-list-item--content > *{
			color: $directory-gray-font-color;
		}

		ul.event-list .event-list-item:first-child{
			border-color: $directory-secondary-color;
		}

		ul.event-list .event-list-item:hover .event-list-item--title{
			color: $directory-base-color !important;
		}

		ul.event-list li.event-list-item,
		ul.event-list li.event-list-item:hover,
		.content.content--border-left{
			border-color: $directory-secondary-color;
			color: $directory-black-color;
		}

		.wrapper.back-button__wrapper{
			.back-button{
				color: $directory-base-color !important;

				&:hover, &:focus{
					color: darken($directory-base-color, 8%) !important;
				}

				a{
					color: $directory-base-color !important;

					&:hover, &:focus{
						color: darken($directory-base-color, 8%) !important;
					}
				}
			}
		}

		.event-block--step
		.time-table__time-divider{
			color: $directory-gray-font-color;
		}

		ul.event-list .event-list-item--descr-trigger:hover{
			background-color: $directory-secondary-color;
		}

		.event-block--title-timezone .event-header,
		.time-table__time-divider hr{
			border-color: $directory-secondary-color !important;
		}


		.time-table__range{
			background: $directory-base-color !important;
			color: $directory-black-color !important;
		}

		.calendar__monthly li.calendar__item{
			background-color: $directory-secondary-color !important;
			color: $directory-black-color !important;
		}

		.calendar li.calendar__item--active{
			background-color: $directory-base-color !important;
			color: $directory-black-color !important;
		}

		.event-description.hidden,
		.new-event--client-form__wrapper,
		.event-heading .event-heading--header title,
		.event-header .react-select__single-value,
		.event-header .react-select__dropdown-indicator,
		.locked-timezone,
		.event-block--step .title{
			color: $directory-black-color !important;
		}

		.date__day{
			color: $directory-base-color !important;
		}

		.sidebar.event-block--step-item__sidebar{
			background-color: $directory-secondary-color !important;
			color: $directory-black-color !important;
		}

		.btn-group-column .btn,
		.cookies__card-btn.btn--alt,
		input[type="submit"],
		input.btn,
		.btn.btn--primary{
			background: $directory-base-color !important;
			color: $directory-black-color !important;

			&:hover, &:active, &:focus{
				background: darken($directory-base-color, 8%) !important;
				color: $directory-black-color !important;
			}
		}

		.cookies__card-text a,
		.btn.btn--link{
			color: $directory-base-color !important;
			background: transparent !important;
			&:hover, &:active, &:focus{
				color: darken($directory-base-color, 8%) !important;
				background: transparent !important;
			}
		}
	}
}