@import "react-datepicker";

.rf--public{
	position:relative;
	font-family: $titleFont;
	border-radius: 15px;
	padding: 25px 24px 30px;
	width: calc(100% - 30px);
	max-width: 1000px;
	margin: 22px auto;
	border: 1px solid $border-color;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	background: rgba(#fff, 0.26);

	&:before{
		backdrop-filter:blur(12px);
		-webkit-backdrop-filter:blur(12px);
		-moz-backdrop-filter:blur(12px);
		position: absolute;
		display: block;
		border-radius: 15px;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
	}

	@include break-max(500px){
		width: 100%;
		margin-top: 0;
		border-radius: 0;
	}

	&_header{
		.title{
			font-size: 24px;
			margin: 0 0 16px 0;
			padding: 0;
			line-height: 1.36;
		}
	}


}