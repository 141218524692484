// form
.form-group {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    label {
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
        opacity: 0.8;
    }
    .form-text {
        font-size: 12px;
    }

    .form-error {
        font-size: 12px;
        color: #E90000;
        font-style: italic;
        opacity: 0.8;
    }
}
.form-control {
    width: 100%;
    padding: 0 18px;
    font-size: 16px;
    background-clip: padding-box;
    border: 1px solid #EEEEEE;
    background-color: rgba(#fff, 0.3);
    border-radius: 5px;
    color: $main;
    //transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus {
        color: $main;
        background-color: #fff;
        border: 1px solid $main !important;
        outline: 0;
    }
}

.field-container{
    display: flex;
    .title{
        margin: 0;
        padding: 0;
        width: auto;
    }

    .form-group{
        margin: 0;
        flex: 1;
        min-width: 130px;
    }

    &.align--center{
        align-items: center;
    }
    &.direction--row{
        flex-direction: row;
    }
    &.grid-x-gap-8{
        column-gap: 8px;
    }
    &.grid-x-gap-20{
        column-gap: 20px;
    }
    &.grid-y-gap-8{
        row-gap: 8px
    }
}

textarea, .textarea{
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100px;
}

@mixin placeholder-style{
    font-size: 16px;
    color: #ABBCCC;
    //font-family: $mainFont;
    font-style: normal;
    font-weight: 100;
}

::-webkit-input-placeholder {
    @include placeholder-style;
}
::-moz-placeholder {
    @include placeholder-style;
}
:-ms-input-placeholder {
    @include placeholder-style;
}
input:-moz-placeholder {
    @include placeholder-style;
}

select.select,
select,
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]),
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
    height: 40px;
    line-height: 40px;
    color: $main;
}

.form-group{
    .one-row{
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-top: 5px;

        label{
            font-size: 14px;
        }

        input[type="checkbox"]{
            height: 26px;
            width: 26px;
            position: absolute;
            top: -1px;
            left: 0;
            opacity: 0;
            z-index: 1;
            margin: 0;
        }

        .custom-checkbox{
            position: relative;
            top: -5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            width: 26px;
            min-width: 26px;
            border: 1px solid #EEEEEE;
            background-color: #fff;
            border-radius: 5px;
            color: $main;
            margin-right: 12px;

            .ico{
                position: relative;
                display: inline-block;
                height: 14px;
                width: 14px;
                transform: scale(0.5);
                visibility: hidden;
                background: url("../check-icon.svg") no-repeat center;
                background-size: 14px;
                transition: all 0.2s ease-out;
            }
        }

        input:checked + .custom-checkbox{
            .ico{
                visibility: visible;
                transform: scale(1);
            }
        }
    }
}

textarea,
textarea.form-control{
    padding-top: 12px;
    padding-bottom: 12px;
}

.booking-page__main-wrapper{
    .event-header{
        .Select-input > input{
            height: 36px;
            line-height: 36px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.form-group--participants{
    &.force-focused {
        .react-multi-email {
            border: 1px solid #0B3052;
            background: #fff;
        }
    }
    .react-multi-email{
        margin-bottom: 8px;

        input[type="text"] {
            width: 100%;
            padding: 0 18px;
            font-size: 16px;
            background-clip: padding-box;
            background-color: rgba(#fff, 0);
            color: $main;

            &:focus {
                color: $main;
                background-color: #fff;
                outline: 0;
            }
        }
    }

    .react-multi-email [data-tag] {
        margin: 2px;
        background-color: #f1f6ff;
        padding: 6px 5px 7px 10px;
        color: #000;
        font-weight: 300;
        border-radius: 4px;
        font-size: 13px;
        line-height: 1;
        max-width: 100%;

        //&:hover{
        //    background-color: darken(#f1f6ff, 6%);
        //}
    }

    .react-multi-email [data-tag] [data-tag-handle] {
        margin-left: 5px;
        cursor: pointer;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 22px;
        margin-top: -4px;
        margin-bottom: -5px;
        font-size: 17px;
        color: #ABBCCC;

        &:hover{
            color: #FF3259;
        }
    }
}

.react-select{
    &__control{
        border-color: #eee !important;
    }
    &__value-container{
        padding-right: 0 !important;
    }
    &__value-container input,
    &__input{
        height: unset !important;
        line-height: 30px !important;
    }
    &__input-container{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    &__indicator-separator{
        display: none;
    }
    &__dropdown-indicator{
        padding: 0 6px 0 0 !important;
    }
}

.custom-date-field{
    flex-wrap: nowrap;

    .react-datepicker-wrapper{
        width: auto;
        .react-datepicker__input-container{
            i{
                border: 1px solid #EEEEEE;
                background-color: rgba(#fff, 0.3);
                min-width: 36px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: inline-flex !important;
                align-items: center;
                justify-content: center;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}