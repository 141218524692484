.multiple-meeting{
	&--wrapper{
		position: relative;
		margin: 22px 0 0 0;
		padding: 0;
		width: 100%;
		height: auto;

	}
	&--content{
		padding: 20px;
		border-radius: 6px;
		border: 1px solid #E7ECF7;
		background: rgba(#FFF, 0.2);
	}
	&--header{
		margin: 0 0 16px 0;

		.title{
			text-align: unset;
			margin: 0;
			padding: 0px;
		}
	}
	&--body{}
	&--btn-bar{
		padding-top: 22px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		gap: 8px;
	}
	&--block{
		margin-bottom: 16px;
		&:last-child{margin-bottom: 0;}

		&_title{
			font-size: 14px;
			padding: 0;
			margin: 0 0 8px 0;
			line-height: 1;
		}
		&_top-info{
			margin: -15px 0 25px 0;
		}
	}
	&--slots-list{
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 6px;

		&__item{
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: auto;
			min-width: 100px;
			height: 32px;
			padding: 4px 28px 4px 20px;
			border-radius: 4px;
			border: 1px solid #06ADEF;
			background: rgba(#fff, 0.6);
			color: #06ADEF;
			font-size: 14px;

			&-remove{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: transparent;
				border: none;
				padding: 0;
				margin: 0;
				height: 30px;
				width: 30px;
				cursor: pointer;
				transition: all 0.2s ease-out;

				svg{
					height: 14px;
					line-height: 0.8;
					path{fill: #ABBCCC;}
				}

				&:hover{
					svg{
						path{fill: #FF3259;}
					}
				}
			}
		}
	}
}

// indicator in calendar item
.calendar__item{
	.calendar__multiple-select{
		position: absolute;
		right: -8px;
		bottom: -8px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		padding: 0;
		margin: 0;
		min-width: 22px;
		height: 22px;
		font-size: 12px;
		font-weight: bold;
		border: 2px solid;
		border-radius: 30px;
		z-index: 9999;
	}
	&.calendar__item--multiple-selected{
		position: relative;
		border: 2px solid;

		&:before{
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			top: 0;
			left: 0;
			border-radius: 4px;
			opacity: 0.33;
		}
	}
}