ul.event-list {
    margin-top: -22px;
    padding: 0;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;

    .event-list-link{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: block;
        color: $main;
        z-index: 0;

        .event-list-item{
            border-bottom: 1px solid $border-color;
        }

        &:hover {
            .event-list-item--title{
                color: $color-blue;
            }
        }
        &:first-child{
            .event-list-item{
                //border-top: 1px solid $border-color;
            }
        }
    }

    .event-list-item {
        position: relative;
        display: flex;
        align-items: flex-start;
        color: $main;
        padding: 18px 20px;
        gap: 4px 14px;
        border-bottom: 1px solid $border-color;
        &:first-child{
            border-top: 1px solid $border-color;
        }
        &:last-child{
            border-bottom: none;
        }
        @include break-max($mob){
            padding-left: 5px;
            padding-right: 5px;
        }

        .react-select__menu{
            z-index: 999;
        }

        &--duration-selector{
            margin: 0;
            padding: 0;
            position: relative;
        }
        &--caption{
            flex: 1;
            display: flex;
            align-items: center;
            min-height: 30px;
        }
        &--additional-info{
            align-items: center;
            gap: 6px 20px;
            &.--hide-on-mob{
                display: none;
                @include break-min(640px){
                    display: flex;
                }
            }
            &.--show-on-mob{
                display: flex;
                padding-top: 10px;
               @include break-min(640px){
                   display: none;
               }
            }

            .event-flexible-duration{
                margin: 0;
                padding: 0;

                .react-select__control{
                    min-height: 30px;
                    background-color: rgba(#fff, 0.8);
                    border-color: $border-color;
                    .react-select__value-container{
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    .react-select__input{
                        height: 18px;
                    }
                    .react-select__single-value{
                        font-weight: normal;
                        font-size: 14px;
                    }
                }
            }
        }

        &--price{
            display: inline-flex;
            align-items: center;
            min-height: 30px;
            font-size: 14px;
            min-width: 50px;
            text-align: center;
            font-weight: bold;
            color: $main;
            //margin-right: -34px;
            white-space: nowrap;
        }

        &--title{
            font-size: 16px;
            margin-bottom: 0;
            color: $main;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 5px 20px;
        }

        .ico{
            display: inline-flex;
            align-items: center;
            line-height: 1;
            color: $main;
            //margin-right: 15px;

            svg{
                height: 20px;
            }


            &--clock{
                position: relative;
                height: 30px;
                line-height: 1;
                //bottom: -0.06em;
            }
            &--next{
                font-weight: 400;
                position: relative;
                //margin-left: 60px;
                //margin-left: 20px;
                //padding-right: 6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                //top: 3px;
                top: 0;
                margin-right: 0;
                z-index: -1;

                svg{
                    height: 26px;
                }
            }
            &--user{
                svg{
                    height: 26px;
                }
            }
        }

        &--no-descr{
            //align-items: center;
            span{
                line-height: 1;
            }
            .event-list-item--title{
                margin-bottom: 0;
            }
            .ico{
                &--next{
                    top: 0;
                }
            }
        }

        &--body {
            font-size: 12px;
            line-height: 1.72;
            width: 100%;
            //max-width: calc(100% - 124px);
            flex: 1;
        }

        &--descr-trigger{
            position: absolute;
            //top: auto;
            //bottom: 18px;
            //right: 50px;
            //height: 40px;
           // width: 40px;
            width: 33px;
            height: 33px;
            right: -45px;
            top: 6px;
            left: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            padding: 0;
            margin: 0;
            line-height: 1;
            cursor: pointer;
            z-index: 10;
            background: transparent;
            color: #788291;
            border-radius: 50%;
            &.show-on-mob{
                display: inline-flex;
                @include break-min(680px){
                    display: none;
                }
            }

            svg{
                height: 16px;
            }
            &:hover{
                background: #F1F6FF;
            }
        }

        &--descr-wrapper{
            position: relative;
            &.--full-descr{
                .event-list-item--descr-trigger{
                    top: auto;
                    bottom: 0;
                }
            }
        }

        &--duration {
            position: relative;
            padding-top: 10px;
            font-weight: 500;
            font-size: 14px;
            min-width: 100px;
        }

        &--content{
            position: relative;
            padding-top: 10px;

            &--short{
                width: 100%;
                position: absolute;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                opacity: 1;
                visibility: visible;
                transition: all 0.40s ease-out;

                > *,
                .ql-size-small,
                .ql-size-large,
                .ql-size-huge,{
                    font-size: 13px !important;
                    line-height: 22px !important;
                }

                a{
                    position: relative;
                    z-index: 2;
                }
            }
            &--full{
                opacity: 0;
                transition: all 0.40s ease-out;

                a{
                    position: relative;
                    z-index: 2;
                }
            }
        }

        .collapse {
            width: 100%;
            max-height: 30px;
            transition: max-height 0.50s ease-out;
            overflow: hidden;

            .event-list-item--content{
                > * {
                    font-size: 13px !important;
                    line-height: 22px !important;
                }
            }
        }
        .collapse.collapsed {
            max-height: 1200px;
            transition: max-height 0.35s ease-in;

            .event-list-item--content{
                &--short{
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.40s ease-in;
                    > *,
                    .ql-size-small,
                    .ql-size-large,
                    .ql-size-huge,{
                        font-size: 13px !important;
                        line-height: 22px !important;
                    }
                }
                &--full{
                    opacity: 1;
                    transition: all 0.40s ease-in;
                }
            }
        }

        &:hover{
            .event-list-item--title{
                font-size: 16px;
                margin-bottom: 0;
                color: $color-blue;

                .event-list-item--price{
                    color: $main;;
                }
            }
        }
    }
}

.event-list--member{
    .event-list{
        &-item{
            &--no-descr{
                align-items: center;
            }
            &--avatar{
                position: relative;
                height: 30px;
                width: 30px;
                min-width: 30px;
                border-radius: 50%;
                object-fit: cover;
                transition: all 0.5s ease;
                z-index: 4;

                &:hover{
                    box-shadow: 0px 0px 20px 3px rgba(#071725, 0.2);
                    transform: scale(3.5) translate(-32%, 10%);
                    @include break-max(880px){
                        transform: scale(3.5) translate(26%, 10%);
                    }
                }
            }
        }

    }
}


input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
select:focus,
textarea {
    font-size: 16px !important;
}