html .dark-mode{

	.DayPickerNavigation_button__default{
		background-color: $body-bg--dark;
		color: $color-neutral--dark;
	}

	.react-datepicker {
		color: $color-second-text-color--dark;
		&__placeholder {
			color: #ABBCCC;
		}
	}

	.react-datepicker__quarter-text:hover {
		background-color: $sidebar-bg--dark;
		color: $color-neutral--dark;
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header,
	.react-datepicker-year-header{
		color: $color-neutral;
	}

	.react-datepicker {
		background: $body-bg--dark;
		border-color: $color-border--dark;
		@include shadowDarkMode;

		&__quarter--in-range {
			color: $color-white;
			background: $color-primary--dark;
		}

		&--week-selector {
			.react-datepicker__week:hover {
				.react-datepicker__day {
					color: $color-white;
					background: $color-primary--dark;
				}
			}
		}

		&__navigation {
			color: $color-second-text-color--dark;
		}

		&__navigation-icon {
			&:before {
				color: $color-neutral--dark;
			}
		}

		&__header__dropdown--select{
			.react-datepicker__month-dropdown-container--select,
			.react-datepicker__year-dropdown-container--select{

				select,
				.react-datepicker__month-select,
				.react-datepicker__year-select{
					color: $color-neutral--dark;
					border-color: $color-border--dark;
				}
			}
		}

		&__day-name {
			color: $color-neutral;
		}


		&__day,
		&__month-text,
		&__year-text {
			color: $color-neutral--dark;
			background: $second-brand-color--dark;

			&:hover {
				color: $color-white;
				background: $color-primary--dark;
			}

			&--selected,
			&--keyboard-selected {
				color: $color-white;
				background: $color-primary;
			}
		}

		&__day {
			&--in-range {
				color: $color-white;
				background: $color-primary--dark;
			}
		}
	}

	.SingleDatePicker_picker{border-radius: 5px;}

	.DayPicker__withBorder{
		@include shadowDarkMode;
	}

	.CalendarMonth_caption{
		color: $color-neutral--dark;
	}

	.DayPicker_weekHeader {
		color: $color-neutral;

		small{
			color: $color-neutral;
		}
	}

	.DayPicker_weekHeader_li{
		color: $color-neutral;
	}

	.DayPicker_transitionContainer{
		color: $color-neutral--dark;
	}

	.DayPickerNavigation_button__horizontalDefault{
		&:before {
			color: $color-neutral--dark;
		}

		&:hover{
			border-color: $color-border--dark;
		}
	}

	.CalendarDay__default {
		border-color: $color-border--dark;
		color: $color-second-text-color--dark;
		background: $second-brand-color--dark;

		&:hover{
			background-color: $color-marketing;
			color: $color-neutral-dark;
		}

		&.CalendarDay__selected{
			background-color: $color-marketing;
			color: $color-neutral-dark;
		}
	}

	.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
	.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
		border-top-color: $body-bg--dark;
	}
}

html .react-datepicker {
	&__input-container {
		vertical-align: middle;
	}

	&__fake-input {
		display: block;
	}

	&__placeholder {
		font-size: 16px;
		color: #ABBCCC;
		//font-family: $mainFont;
		font-style: normal;
		font-weight: 100;
	}
}

html .react-datepicker {
	padding: 15px;
	font-family: $titleFont;
	border: none;
	border-radius: 5px;
	box-shadow: 2px 2px 15px rgba(45, 51, 72, .1);

	&__month-container{
		clear: both;
	}

	&__quarter--in-range {
		color: $color-white;
		background: $color-primary;
	}

	&--week-selector {
		.react-datepicker__week:hover {
			.react-datepicker__day {
				color: $color-white;
				background: $color-primary;
			}
		}
	}

	&__navigation {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		top: 15px;
		padding: 0;
		margin: 0;
		line-height: 1;
		border: none;

		&--previous {
			left: 15px;
		}

		&--next {
			right: 15px;
		}
	}

	&__navigation-icon {
		width: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		font-size: 0;
		text-indent: 0;

		&:before {
			position: relative;
			display: inline-block;
			width: auto;
			border: none;
			transform: none;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: 'Font Awesome 6 Pro';
			font-weight: 400;
			font-size: 14px;
			color: $color-neutral-dark;
		}

		&--previous:before {
			content: '\f053';
		}

		&--next:before {
			content: '\f054';
		}
	}

	&__header {
		margin-bottom: 8px;
		padding: 0;
		border: none;
		background: none;
	}

	&__header__dropdown--select{
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px 0;

		.react-datepicker__month-dropdown-container--select,
		.react-datepicker__year-dropdown-container--select{
			margin: 0 4px;
			padding: 0;

			select,
			.react-datepicker__month-select,
			.react-datepicker__year-select{
				height: 30px;
				line-height: 30px;
				color: $color-neutral-dark;
				border-radius: 5px;
				border: 1px solid $border-color;
				padding: 0 8px;
			}
		}
	}

	&__current-month {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 24px;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 15px;
	}

	&__day-names {
		display: flex;
		align-items: center;
		gap: 4px;
		margin-bottom: 0;
	}

	&__day-name {
		width: 32px;
		margin: 0;
		font-weight: 300;
		line-height: 1;
		text-transform: uppercase;
		color: $color-neutral;
	}

	&__day--disabled {
		opacity: 0.3;
	}

	&__month {
		display: grid;
		gap: 4px;
		margin: 0;
	}

	&__year {
		display: grid;
		gap: 4px;
		margin: 0;
	}

	&__week,
	&__month-wrapper,
	&__year-wrapper {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	&__day,
	&__month-text,
	&__year-text {
		position: relative;
		height: 32px;
		margin: 0;
		font-size: 12px;
		font-weight: 600;
		line-height: 32px;
		border: none;
		border-radius: 4px;
		text-align: center;
		color: $color-neutral-dark;
		background: $color-secondary;

		&:hover {
			color: $color-white;
			background: $color-primary;
			transition: all .25s ease;
		}

		&--selected,
		&--keyboard-selected {
			color: $color-white;
			background: $color-primary;
		}

		&--outside-month {
			pointer-events: none;
			opacity: 0;
			visibility: hidden;
		}
	}

	&__day {
		width: 32px;
		&--in-range {
			color: $color-white;
			background: $color-primary;
		}
	}

	&__month-text {
		min-width: 80px;
		width: auto !important;
		margin: 0 !important;
	}

	&__year-text {
		min-width: 88px;
		width: auto !important;
		margin: 0 !important;
	}

	> .react-datepicker__navigation{
		background: transparent;
		outline: none;
		border: none;
		min-width: 30px;
		width: auto;

		&--previous{
			float: left;
			margin-right: auto;
		}
		&--next{
			float: right;
			margin-left: auto;
		}
	}
}

html .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
html .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
	opacity: 0;
}

.react-datepicker__tab-loop {
	margin-right: -8px;
}


// second datepicker DayPicker
html{
	.SingleDatePicker_picker{border-radius: 5px;}

	.DayPicker__withBorder{
		border: none;
		border-radius: 5px;
		box-shadow: 2px 2px 15px rgba(45, 51, 72, .1);
	}

	.CalendarMonth_caption{
		font-family: $titleFont;
		color: $color-neutral-dark;
		padding-top: 22px;
		padding-bottom: 40px;
	}

	.DayPicker_weekHeader {
		position: absolute;
		top: 56px;
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;
		color: $color-neutral;
		z-index: 2;

		small{
			font-size: 13px;
			color: $color-neutral;
			font-style: normal;
		}
	}
	.DayPicker_weekHeader_ul{
		margin: 0;
		padding: 0;
	}
	.DayPicker_weekHeader_li{
		font-style: normal;
		color: $color-neutral;
	}

	.DayPicker_transitionContainer{
		font-family: $titleFont;
		color: $color-neutral-dark;
		border: none;
		border-radius: 5px;
		box-shadow: none;

		.CalendarMonthGrid_month__horizontal{
			.CalendarMonth_caption{
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}

	.DayPickerNavigation_button__horizontalDefault{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 33px;
		height: 33px;
		padding: 0;
		margin: 0;
		line-height: 1;
		border: none;

		svg{
			display: none;
		}

		&:before {
			position: relative;
			display: inline-block;
			width: auto;
			border: none;
			transform: none;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: 'Font Awesome 6 Pro';
			font-weight: 400;
			font-size: 14px;
			color: $color-neutral-dark;
		}

		&.DayPickerNavigation_leftButton__horizontalDefault:before {
			content: '\f053';
		}

		&.DayPickerNavigation_rightButton__horizontalDefault:before {
			content: '\f054';
		}

		&:hover{
			border-color: $border-color;
		}
	}

	.CalendarMonth_table {
		border-collapse: separate;
		border-spacing: 2px;
		border-radius: 4px;
		overflow: hidden;

		tr{
			border-radius: 4px;

			td{
				border-radius: 4px;
				max-width: 34px;
			}
		}
	}

	.CalendarDay__default {
		border: 1px solid $border-color;
		color: $color-neutral-dark;
		background: $color-secondary-50;
		vertical-align: middle;
		border-radius: 4px;

		&:hover{
			background-color: $color-marketing;
		}

		&.CalendarDay__selected{
			background-color: $color-marketing;
		}
	}
}