.tag-item{
	position: relative;
	margin: 4px;
	display: inline-flex;
	align-items: center;
	min-height: 30px;
	max-width: 400px;
	padding: 2px 8px;
	background: rgba(#fff, 0.2);
	border-radius: 4px;
	border: 1px solid $border-color;
	cursor: pointer;

	&.enabled{
		border: 1px solid darken($border-color, 10%);
		background: #F1F6FF ;
		.tag-item__name{
			opacity: 1;
		}
	}

	&__color{
		margin-right: 8px;
		display: inline-block;
		height: 8px;
		width: 8px;
		min-width: 8px;
		border-radius: 50%;
	}
	&__name{
		font-size: 13px;
		line-height: 1.1;
		font-style: italic;
		opacity: 0.7;
		padding-right: 2px;
	}
}

.tag-block{
	&__wrapper{
		margin-bottom: 22px;
		max-width: 780px;
		margin-left: auto;
		margin-right: auto;

		.collapse.collapsed {
			.tag-block__list-hidden{
				max-height: 1400px;
				transition: max-height 0.35s ease-in;
			}
		}

		.collapse.collapsed + .btn-bar{
			.tag-block__list-trigger-all{
				display: none;
			}
			.tag-block__list-trigger-less{
				display: inline-block;
			}
		}
	}
	&__list-hidden,
	&__list{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: stretch;
		margin-left: -4px;
		margin-right: -4px;
	}
	&__list-hidden{
		max-height: 0px;
		transition: max-height 0.50s ease-out;
		overflow: hidden;
	}
	&__list-trigger-all{
		display: inline-block;
	}
	&__list-trigger-less{
		display: none;
	}

	&__btn-bar{
		padding: 22px 0;
		text-align: center;

		button{
			cursor: pointer;
			background: transparent;
			padding: 2px 6px;
			font-size: 14px;
			margin: 4px;
			border: none;
			outline: none;
			font-style: italic;

			&:hover{
				color: $color-blue;
			}
		}
	}
}

