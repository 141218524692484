@import "../ui-kit--sm-meet/vars";

@import "../ui-kit--sm-meet/font-awesome-6/fontawesome";
@import "components/index";
// theme styles inline templates/booking/theme_styles.html.twig

$color-dark: #0B3052;
$color-blue: #06ADEF;
$color-green: #00D38B;
$color-red: #FF3259;

//directory theme colors
$directory-base-color: #c2a65c;
$directory-secondary-color: #f3edde;
$directory-secondary-alt-color: #faf8f2;
$directory-black-color: #000000;
$directory-gray-font-color: #737373;
$directory-gray-background-color: #f8f8f8;


.text-darker {
	color: $color-dark;
}

.text-danger {
	color: $color-red;
}

.text-success {
	color: $color-green;
}


// common
html {
	box-sizing: border-box;
	min-height: 100vh;
	min-height: -webkit-fill-available;
}
*, *:before, *:after {
	box-sizing: border-box;
}

body {
	font-family: $mainFont;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.4;
	color: $main;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	min-height: 100vh;
	overflow-x: hidden;
}

html body #scheduling .material-icons {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 6 Pro";
	font-weight: 300;
}

h1, h2, h3, h4 {
	font-weight: 600;
	line-height: 1.4;
	font-family: $titleFont;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 28px;
}

h3 {
	font-size: 20px;
	@include break-max($mob){
		font-size: 18px;
	}
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

a {
	text-decoration: none;
	color: $primary;
	&:hover {
		text-decoration: none;
		color: darken($primary, 30%);
	}
	&.small {
		font-size: 13px;
	}
}

// layout
section {
	padding: 40px 0;

	&.hr {
		width: 100%;
		margin: 0 auto;
		height: 1px;
		background-color: #dee3e9;
		padding: 0;

		@include md {
			width: 700px;
		}
	}
}

.small-padding {
	padding: 20px 0;
}

.wrapper {
	display: flex;
	width: 100%;
	max-width: 980px;
	margin: 0 auto;
	padding: 0 20px;
	flex-direction: column;

	@include md {
		flex-direction: row;
	}
}

.event-block--details-form,
.wrapper__calendar{
	display: block;
	max-width: 780px;
}
.wrapper__calendar{
	max-width: 980px;
}

.calendar-wrapper{
	width: 100%;
	max-width: 780px;
	margin-left: auto;
	margin-right: auto;
}

.step-title{
	text-align: center;
	display: block;
	width: 100%;
	margin-bottom: 22px;
	font-size: 16px;
	font-weight: normal;

	&.title--select-meeting{
		margin-bottom: 50px;
	}
}

.wrapper__user-header{
	align-items: center;
}

.sidebar {
	width: 100%;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	margin-bottom: 12px;

	&.event-block--step-item__sidebar{
		font-family: $titleFont;
		border-radius: 15px;
		padding: 22px 22px;
		background: rgba(#fff, 0.36);
		width: 100%;
		margin-bottom: 22px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		//position: sticky;
		//position: -webkit-sticky;
		//top: 12px; /* required */

		@include md {
			min-width: 370px;
			max-width: 370px;
			margin-right: 40px;
			margin-bottom: 0;
		}
		@include break-max($mob){
			margin-bottom: 45px;
		}
	}

	@include md {
		flex-direction: column;
		width: 230px;
		justify-content: flex-start;
		margin-bottom: 0;
	}
}

.grecaptcha-badge{
	z-index: 99999;
}

.new-event--client-form{
	padding-bottom: 60px;
}

.create-event--block{
	.form-group{
		.react-select__menu{
			z-index: 102;

			.react-select__menu-list{

			}
		}
	}
}

.content {
	flex: 1;
	margin-top: 30px;
	&.content--border-left {
		@include md {
			border-left: 1px solid #dee3e9;
		}
	}

	@include md {
		//padding-left: 35px;
		margin-top: 0;
	}
}

.event-list .event-flexible-duration{
	z-index: 2;
	border-bottom: 0;
}

.event-flexible-duration,
.event-header {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-bottom: 1px solid #C4C4C4;
	padding-bottom: 15px;
	margin-bottom: 20px;

	@include break-min($mob){
		flex-direction: row;
		align-items: flex-start;
	}

	.dropdown-toggle{
		min-width: 300px;
		@include break-max($mob){
			min-width: 270px;
		}
		> div{
			width: 100%;
		}
	}

	.dropdown-toggle,
	.Select--single{
		@include break-max($mob){
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 12px;
			width: 100%;
		}
	}

	.react-select{
		&__control{
			padding-right: 4px;
			backdrop-filter: blur(3px);
			background-color: rgba(white, 0.1);
			border: 1px solid rgba(white, 0.01);
			box-shadow: 0px 2px 4px -4px rgba(black, 0.15);
		}
		&__value-container{
			padding-left: 16px;
			padding-right: 12px;
		}
		&__single-value{
			font-family: $mainFont;
			color: darken($color-grey, 5%);
		}
		&__input-container{
			padding-top: 1px;
			padding-bottom: 1px;
			input[type='text']{
				height: 28px;
				line-height: 28;
			}
		}
		&__dropdown-indicator{
			color: darken($color-grey, 5%);
			svg{
				height: 16px;
			}
		}
		&__indicator-separator{
			display: none;
		}
	}
}

.event-heading {
	flex: 1;
	margin: 5px 0;
	padding-right: 25px;
	@include break-max($mob){
		padding-right: 0;
		margin-bottom: 14px;
	}

	.event-heading--header{
		display: flex;
		align-items: flex-start;
		gap: 4px 8px;

		.material-icons {
			position: relative;
			bottom: -3px;
			min-width: 26px;
			text-align: center;
			font-size: 19px;
		}

		.title--h3{
			//display: flex;
			//align-items: flex-start;
			//
			//.btn{
			//	margin-left: auto;
			//	margin-right: 0;
			//	padding: 0;
			//	width: 40px;
			//	min-height: 30px;
			//	height: 30px;
			//	background: transparent;
			//	border: none;
			//	justify-content: flex-end;
			//
			//	svg{
			//		height: 18px;
			//	}
			//}
		}
	}

	.event-description--wrapper{
		//display: none;
	}
}

.dropdown {
	display: flex;
	justify-content: flex-start;
	padding-left: 10px;
	&-toggle {
		font-size: 13px;
		background-color: transparent;
		border-color: transparent;
		display: flex;
		align-items: center;
		color: $primary;
		cursor: pointer;
		text-align: left;
		padding: 0;
		border-width: 0;
	}

	@include sm {
		justify-content: flex-end;
	}
}

.back-button{
	display: inline-flex;
	gap: 8px;
	align-items: center;
	min-height: 30px;

	.material-icons{
		//margin-right: 10px;
	}


	&__wrapper{
		padding-top: 40px;
		padding-bottom: 10px;

		&.has-border{
			//margin-bottom: 35px;
			//padding-bottom: 25px;
			border-bottom: 1px solid #dee3e9;

			&.no-border{
				padding-bottom: 0;
				border-bottom: none;
			}
		}


	}

	&__to-organization{
		padding-top: 0;
		margin-bottom: 22px;

        .ico{
			margin-right: 8px;
			svg{
				height: 20px;
				position: relative;
				bottom: -2px;
			}
		}
	}
}

.event-block--details-form{
	.event-block--step.event-block--step-form{
		padding-top: 25px;
	}
}

.event-description--wrapper{
	padding: 0;
	//.event-description{
	//	transition: max-height 0.50s ease-out;
	//	overflow: hidden;
	//	max-height: 1100px;
	//	&.hidden{
	//		transition: max-height 0.50s ease-out;
	//		max-height: 0px;
	//	}
	//}

	.rich-text{
		margin-bottom: 20px;
		border-bottom: 1px solid #C4C4C4;
		padding-bottom: 15px;
		> *{
			//font-size: 14px;
			//font-weight: normal;
			//line-height: 1.4;
		}
	}
}

.booking-page__main--example{
	.calendar-wrapper{
		.calendar__monthly--nav,
		.calendar__days-title{
		//.calendar__month-title{
			display: none;
		}
		.calendar{
			gap: 6px;
			.calendar__nav{

			}
		}
	}
}

.event-block{
	&--title-timezone{
		padding: 12px 0 0 0;

		.wrapper{
			display: block;
		}
	}
	&--select-time{
		padding-top: 15px;
	}
	&--step-form,
	&--step-hours{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 100%;

		.step-title {
			text-align: left;
		}

		@include md {
			flex-direction: row;
			align-items: flex-start;
		}
	}

	&--step-item__calendar {
		width: 100%;
	}

	&--step-calendar-and-hours {
		&.calendar-and-hours--weekly,
		&.calendar-and-hours--monthly {
			display: flex;
			align-items: flex-start;
			padding: 0 0 60px 0;
			margin: 0;
			gap: 20px 60px;
			@include break-max($mob-xl) {
				gap: 20px 45px;
			}
			@include break-max($mob) {
				flex-wrap: wrap;
				flex-direction: column;
			}

			.event-block--step-item__calendar-days {
				width: 100%;
				order: -1;
				@include break-min($mob) {
					position: sticky;
					position: -webkit-sticky;
					top: 0; /* required */

					display: block;
					//order: -1;
					flex: 1;
					width: 100%;
					min-width: 380px;
				}
			}

			.event-block--step-hours {
				@include break-min($mob) {
					padding-top: 48px;
					flex: 1;
					max-width: 410px;
				}
			}

			.event-block--step-item__sidebar {
				//@include break-min($mob) {
					display: none;
				//}
			}

			.event-block--step-hours + .event-block--step-item__calendar-days {
				//@include break-max($mob) {
				//	display: none;
				//}
			}
		}

		.select-time--wrapper{
			width: 100%;
			.meeting-duration {
				font-weight: 500;
			}
		}

		//&.calendar-and-hours--weekly {
		//	.event-block--step-hours + .event-block--step-item__calendar-days {
		//		display: none;
		//	}
		//}

		&.calendar-and-hours--weekly{
			gap: 20px 50px;
			@include break-max($mob-xl) {
				gap: 20px 35px;
			}
			.calendar__days-title {
				//padding-left: 10px;
				//padding-right: 10px;
			}
			.calendar .calendar__nav{
				top: -68px;
				z-index: 2;
				@include break-min(480px){
					top: -62px;
				}
			}
			.calendar li.calendar__nav--left {
				left: 0;
			}
			.calendar li.calendar__nav--right {
				right: 0;
			}
			.calendar.calendar__weekly{
				gap: 6px;
				align-items: stretch;
				padding-left: 0;
				padding-right: 0;
				padding-top: 10px;

				.calendar__day,
				.calendar__month{
					display: none;
				}

				li.calendar__item{
						height: auto;
				}

				@include break-min($mob){
					li.calendar__item{

					}

					.calendar__day{
						padding: 0;
						margin: 0;
						width: auto;
						min-width: unset;
					}
					.calendar__day,
					.calendar__month{
						display: none;
					}
					.calendar__info{

					}
				}
			}
		}
	}
}

.calendar__month-title-for--monthly,
.calendar__month-title-for--weekly {
	text-transform: capitalize;
}
.calendar-wrapper__weekly {
	.calendar__monthly--nav{
		display: none;
	}
}
.event-block--step.event-block--step-calendar-and-hours.calendar-and-hours--monthly .event-block--step-hours + .event-block--step-item__calendar-days .calendar__weekly{
	gap: 6px;
}

// home page event list
.booking-page__main{
	> .wrapper{
		display: block;
	}
}

// main calendar
.calendar-wrapper{
	&__monthly {
		max-width: 490px;
		position: relative;
		@include break-max(480px) {
			max-width: unset;
			width: auto;
			margin-left: -10px;
			margin-right: -10px;
		}
	}
}
.calendar {
	display: flex;
	position: relative;
	justify-content: space-between;
	width: 100%;
	//flex-direction: column;
	padding: 20px 0 0 0;
	margin-top: 0;
	//outline: 1px solid red;
	@include md {
		flex-direction: row;
		padding-top: 20px;
		margin-top: 0;
		//padding-left: 15px;
		//padding-right: 15px;
	}
	//@media only screen and (min-width: 768px) and (max-width: 820px) {
	//	padding-left: 40px;
	//	padding-right: 40px;
	//}

	li {
		&.calendar__item {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: unset;
			padding-top: 20px;
			padding-bottom: 20px;
			background-color: #ececec;
			border-radius: 5px;
			gap: 5px;
			color: $main;
			transition: all .1s ease;
			//margin-bottom: 8px;
			font-family: $titleFont;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

			&.calendar__item--original-schedule{
				background-color: $original-schedule-color;
			}
			@include md {
				flex-direction: column;
				height: 90px;
				width: 90px;
				margin-bottom: 0;
			}
			@media only screen and (min-width: 768px) and (max-width: 820px) {
				height: 80px;
				width: 80px;
			}

			&:hover {
				cursor: pointer;
				background-color: #d9d9d9;

				&.calendar__item--original-schedule{
					background-color: $original-schedule-color-hover;
				}
			}

			&--disabled {
				opacity: 0.65;
				box-shadow: none;
				cursor: not-allowed;

				&:hover {
					cursor: default;
					background-color: #ececec;
				}
			}

			&--not-available {
				pointer-events: none;
				filter: grayscale(1);
				cursor: not-allowed;
			}

			&--active {
				color: white;
				background-color: $primary;
				//box-shadow: inset 0px 0px 10px 3px $primary;
				&:hover {
					cursor: default;
					background-color: $primary;
				}
			}
		}
		&.calendar__nav {
			position: absolute;
			top: -28px;
			//top: -40px;

			//@include md {
			//	top: 20px;
			//}

			&--left {
				left: 0;

				//@include md {
				//	left: -41px;
				//}
				//@media only screen and (min-width: 768px) and (max-width: 820px) {
				//	left: 0;
				//}
			}
			&--right {
				right: 0;

				//@include md {
				//	right: -41px;
				//}
				//@media only screen and (min-width: 768px) and (max-width: 820px) {
				//	right: 0;
				//}
			}
			&:hover {
				cursor: pointer;
				.material-icons {
					//color: $main;
				}
			}
			.material-icons {
				font-size: 24px;
				//color: rgba($main, 0.8);
				transition: all .1s ease;
			}
		}

		//.calendar__nav-btn{
		//
		//}
	}
	&__day, &__month {
		font-size: 12px;
		text-transform: uppercase;
		line-height: 1;
		font-weight: normal;
		font-family: $titleFont;
		@include break-max(500px){
			font-size: 10px;
		}
	}
	&__month{
		display: none;
	}
	&__date {
		display: block;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 600;
		//padding: 0px 12px;
		//min-width: 50px;
		text-align: center;
		line-height: 1;
		@include md {
			//padding: 4px 0;
			min-width: unset;
		}
		@include break-max(500px){
			font-size: 16px;
		}
	}
	&__info {
		position: absolute;
		padding: 0;
		line-height: 1;
		left: 0;
		right: 0;
		//top: 21px;
		bottom: 10px;
		text-align: center;
		font-size: 10px;
		font-weight: 500;
		text-transform: lowercase;
		//color: rgba($main, 0.8);
		background: transparent !important;

		@include md {
			//top: auto;
			//left: 0;
			//right: 0;
			//bottom: 1px;
			//padding: 0;
			//text-transform: uppercase;
		}
		@include break-max(600px) {
			font-size: 9px;
		}
	}


	&__month-title {
		text-align: center;
		font-size: 16px;
		line-height: 1;
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		flex-wrap: wrap;

		.divider{
			&:last-child{
				display: none;
			}
		}


		&-for--weekly {
			//display: none;
			//@include break-min($mob){
			margin: 0;
			//}
		}
	}

	&__days-title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		list-style: none;
		padding: 0;
		position: relative;
		margin-bottom: -38px;

		&-for--weekly {
			margin-bottom: -2px;
			//@include break-max($mob){
				//display: none;
			//}
		}

		@include break-max($mob) {
			//display: none;
		}

		li {
			position: relative;
			height: auto;
			width: calc(14.28% - 6px);
			text-align: center;
			margin: 0 3px;
			text-transform: uppercase;
			font-size: 12px;

			span {
				font-weight: 300;
				line-height: 1;
			}

			@include break-max(480px) {
				width: calc(14.28% - 4px);
				margin: 2px;
			}
		}
	}

	&__monthly {
		padding-top: 40px;
		margin-bottom: 36px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;

		li.calendar__item {
			position: relative;
			height: auto;
			width: calc(14.28% - 6px);
			margin: 3px;
			min-height: 50px;
			padding-top: 13.3%;
			padding-bottom: 0;
			@include break-max($mob) {
				padding: 0;
				text-align: center;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;

				&.calendar__item--not-available{
					opacity: 0;
					visibility: hidden;
				}
			}
			&--not-available{
				visibility: hidden;
				opacity: 0;
			}

			.calendar__month,
			.calendar__date,
			.calendar__day{
				//display: block;
				left: 0;
				position: absolute;
				width: 100%;
				text-align: center;
			}
			.calendar__info{
				line-height: 1;
				position: absolute;
				top: auto;
				bottom: 4%;
				left: 0;
				right: 0;
				text-align: center;
				@include break-max($mob) {
					display: none;
				}
			}

			.calendar__month{
				line-height: 1;
				top: 8%;
				font-weight: 600;
				@include break-max($mob) {
					display: none;
				}
			}
			.calendar__day{
				bottom: 8%;
				line-height: 1;
				@include break-max($mob) {
					display: none;
				}
			}
			.calendar__date{
				padding: 0;
				top: 50%;
				transform: translateY(-50%);
				@include break-max($mob) {
					position: static;
					transform: none;
					top: 0;
				}
			}

			.calendar__day, .calendar__month {
				display: none;
				@include break-max(600px) {
					font-size: 10px;
				}
			}
			.calendar__date{
				min-width: unset;
				@include break-max(600px){
					font-size: 16px;
					padding: 0px;
				}
			}

			&--disabled{
				.calendar__date,
				.calendar__day{
					opacity: 0.9;
				}
			}
			&--today,
			&--disabled{
				.calendar__day {
					bottom: 20%;
				}
			}
		}

		//experiment view
		@include break-max($mob) {
			//flex-wrap: nowrap;
			//overflow-x: auto;
			//padding: 10px 0;
			margin-bottom: 5px;
		}

		&--nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;

			.calendar__nav-btn {
				display: flex;
				width: 48px;
				height: 30px;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

		.calendar__nav {
			display: none;
		}

		//-- end experiment view --
	}
}

// hide-unavailable-dates
.calendar{
	&.calendar__hide-unavailable-dates{
			li {
				&.calendar__item{
					.calendar{

					}
				}
				&.calendar__item--disabled {
					opacity: 0.015;
					pointer-events: none;
				}

			}
	}
	// WEEKLY VIEW
	&.calendar__weekly{
		&.calendar__hide-unavailable-dates{
			li {
				&.calendar__item--disabled {
					@include break-max($mob){
						//display: none;
					}
				}
			}
		}
	}
	//MONTHLY VIEW
	&.calendar__monthly{
		.calendar__nav{
			display: none;
		}
	}
	&-wrapper__monthly{
		&.wrapper-calendar__hide-unavailable-dates{
			.calendar__days-title.calendar__days-title-for--monthly{
				display: none;
			}
			.calendar__hide-unavailable-dates{
				padding-top: 10px;
				.calendar__item{
					&.calendar__item--disabled{
						display: none !important;
					}
					.calendar__date,
					.calendar__day{
						display: block;
						left: 0;
						width: 100%;
						text-align: center;
					}
					.calendar__date{
						top: 39%;
					}
					.calendar__day{
						@include break-min($mob){
							bottom: 14%;
						}
					}

					&.calendar__item--today{
						.calendar__info{
							display: block;
							bottom: auto;
							top: 8%;
						}
						.calendar__day{
							@include break-max($mob){
								bottom: 8%;
							}
						}
					}
				}
			}
		}
	}

	li.calendar__nav,
	&__nav-bottom__item .calendar__nav-btn{
		display: inline-flex;
		width: 40px;
		height: 40px;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0;
		line-height: 0;
		opacity: 0.9;
		background: rgba(#fff, 0.2);
		border-radius: 5px;

		&:hover{
			opacity: 1;
		}
	}
}

.calendar__nav-bottom {
	//display: flex;
	display: none;

	&__item {
		flex: 1;

		&:hover {
			cursor: pointer;
			.material-icons {
				color: $main;
			}
		}
		.material-icons {
			font-size: 24px;
			color: #c7ccd2;
			transition: all .1s ease;
		}

		&.right {
			text-align: right;
		}
	}

	@include md {
		display: none;
	}
}

.calendar-wrapper__monthly + .calendar__nav-bottom{
	display: none;
}

// scheduling part
.date {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	&__right {
		display: flex;
		flex-direction: column;
	}
	&__number {
		font-size: 48px;
		font-weight: 600;
		line-height: 140%;
		margin-right: 10px;

		@include sm {
			font-size: 50px;
			margin-right: 20px;
			line-height: 1;
		}
	}
	&__day {
		font-size: 12px;
		line-height: 1.2;
	}
	&__month {
		padding-top: 0;
		font-size: 18px;

		@include sm {
			font-size: 22px;
		}
	}
}
.scheduled {
	border-top: 1px solid #C4C4C4;
	padding-top: 12px;

	//&__time {
	//	color: #a4dd6c;
	//	display: flex;
	//
	//	.material-icons {
	//		margin-right: 8px;
	//	}
	//}
	&__time-original {
		color: #dd5737;
		margin-top: 6px;
		display: flex;

		.sidebar-heading{
			text-decoration: line-through;
			white-space: pre-line;
		}

		.material-icons {
			margin-right: 8px;
		}
	}
	//&__zone {
	//	margin-top: 6px;
	//	display: flex;
	//
	//	.material-icons {
	//		color: #dadee3;
	//		margin-right: 8px;
	//	}
	//}

	&.scheduled--with-multiple-slots{
		padding-top: 0;
		border-top: none;

		.details-list{
			padding-bottom: 12px;
			border-bottom: 1px solid #C4C4C4;
			margin-bottom: 12px;
		}

		.multiple-meeting--slots-list__item{
			min-width: 104px;
			padding: 4px 24px 4px 8px;
			&--readonly {
				padding: 4px 10px;
			}


			// dropdown actions in slots
			&-phantom-space{
				display: inline-block;
				min-width: 5px;
				width: 5px;
			}

			.dropdown {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				padding: 0;
				margin: 0;

				&-menu{
					@include break-min($mob){top: 34px !important;}
					.btn-group-column{
						.btn{
							margin: 0;
						}
					}
				}
			}
			.slots-list__item-dropdown-toggler.btn--dropdown{
				flex: 1;
				width: 100%;
				height: 100%;
				justify-content: flex-end;
				padding: 0px;
				min-height: auto;
				border-radius: 6px;

				.icon{
					opacity: 0.46;
					font-size: 16px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 28px;
					height: 28px;
					border-radius: 30px;
					font-weight: 100;
				}

				&:hover, &:focus, &:active{
					.icon{opacity: 1;}
				}
			}
		}
	}
}

.details-list{
	padding: 0;
	margin: 0;
	list-style: none;

	&__item{
		display: flex;
		align-items: flex-start;
		margin-left: -10px;
		margin-right: -10px;
		list-style: none;
		margin-bottom: 4px;
		gap: 12px;
		&:last-child{
			margin-bottom: 0;
		}

		&-col{
			position: relative;
			padding: 5px 10px;
			font-size: 12px;
			line-height: 1.55;
			@include break-max($mob){
				font-size: 14px;
			}

			&--title{
				opacity: 0.75;
				width: 36%;
			}
			&--value{
				width: 64%;
				white-space: normal;
				word-break: break-word;
			}
		}

		&--name{
			clear: both;
			.details-list__item-col--value{
				clear: both;

				.service-color{
					position: relative;
					float: left;
					display: inline-block;
					width: 10px;
					height: 11px;
					min-width: 11px;
					margin-right: 8px;
					border-radius: 50%;
					bottom: -3px;
				}
			}
		}
	}
}


.time-table {
	margin: 20px 0px;
	display: grid;
	width: 100%;
	grid-gap: 6px;
	grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
	//grid-template-columns: repeat(auto-fill, minmax(94px, 1fr));
	padding-left: 54px;

	&__time {
		font-size: 12px;
		line-height: 80%;
		width: 100%;
		text-align: left;
		//display: flex;
		display: none;

		&-number {
			min-width: 50px;
			text-align: right;
		}
		&-line {
			flex: 1;
			margin: 4px 0 0 10px;
			height: 1px;
			background-color: #dee3e9;
		}
	}
	&__range {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		color: #b5bcc4;
		padding: 6px 10px;
		height: 36px;
		background-color: #f7f7f7;
		//margin-left: 80px;
		text-align: center;
		cursor: pointer;
		transition: all .1s ease;
		border-radius: 5px;
		font-size: 14px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.01);

		.rest-slots {
			position: absolute;
			bottom: -2px;
			right: -2px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 11px;
			width: auto;
			min-width: 46px;
			height: 14px;
			padding: 1px 8px;
			margin: 0;
			border-radius: 20px 2px 6px 2px;
			background: rgba(#fff, 0.6);
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

			&__row{
				padding: 0;
				margin: 0;
				font-size: 11px;
				font-weight: $semibold;
				font-family: $mainFont;
				line-height: 0.8;
				display: block;
			}
		}

		span {
			white-space: nowrap;
			font-family: $titleFont;
			padding: 1px 6px;
			line-height: 1.2;

			&.devider,
			&.end-time{
				display: none;
			}
		}

		&.calendar__range--original-schedule{
			background-color: $original-schedule-color;
		}

		&:hover {
			color: white;
			background-color: #a4dd6c;
		}

		&--disabled {
			cursor: default;
			background-image: url('disabled-line.png');
			background-repeat: repeat;

			&:hover {
				background-color: #f7f7f7;
			}
		}
	}

	&__time-divider {
		position: relative;
		left: -52px;
		margin: -4px 0;
		line-height: 1;
		font-size: 11px;
		font-weight: 300;
		grid-column-start: 1;
		grid-column-end: -1;

		hr {
			width: calc(100% - 10px);
			position: absolute;
			top: 50%;
			left: auto;
			right: -42px;
			transform: translateY(-50%);
			border-top: none !important;
			opacity: 0.9;
			line-height: 1;
			text-shadow: none;
			box-shadow: none;
			margin: 0;
			padding: 0;
		}
	}
}

.new-event--client-form{
	&__wrapper{
		width: 100%;
	}
	.form-group{
		.form-control {
			@include md {
				max-width: 320px;
			}
		}

		.selected-flag {
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background: transparent;
				z-index: 100;
			}
		}
	}

	.btn-bar{
		padding-top: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;

		//&--add-participants{
		//	padding-bottom: 22px;
		//	padding-top: 0;
		//	margin-top: -10px;
		//}

		&--row{
			display: flex;
			align-self: center;
			justify-content: center;
			flex-wrap: wrap;
			gap: 8px 16px;
		}
	}
}

.participants{
	&--hint{
		display: flex !important;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 5px;
	}
	&--hint-icon{
		line-height: 1;
		position: relative;
		bottom: -1px;
		svg{
			height: 14px;
			opacity: 0.5;
		}
	}
}

.status{
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 1;
	padding: 4px 20px 4px 16px;
	border-radius: 5px;
	height: 36px;
	font-size: 16px;
	font-weight: normal;
	font-family: $titleFont;

	svg{
		height: 16px;
	}

	i{
		margin-right: 8px;
	}

	&--success{
		background: rgba(#00D38B, 0.2);
		color: #00D38B !important;
	}
}

.form-group .payment-systems__icon img {
	//display: inline-block;
	//width: auto;
	//max-width: 106px;
	//height: auto;
	//max-height: 23px;
	//position: relative;
	//bottom: -2px;
}
.form-group .payment-systems input[type="radio"]:checked + .payment-systems__item-content:after{
	border-radius: 5px;
	opacity: 0.8;
	background: transparent;
	height: 100%;
	width: 100%;
	pointer-events: none;
	border: 2px solid  #5bb9f4;
}
.form-group .payment-systems__item.ps_delay .payment-systems__icon img {
	//max-width: 76%;
}

.new-event--confirmed{
	.confirmed-message{
		display: flex;
		align-items: flex-start;
		min-height: 300px;
		flex-direction: column;

		.status{
			margin-bottom: 22px;
		}

		p{
			font-size: 14px;
			opacity: 0.8;
		}
	}
}

// buttons
input[type="submit"],
input.btn,
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	margin: 0;
	padding: 4px 32px;
	height: 36px;
	min-height: 36px;
	color: $main;
	cursor: pointer;

	background-color: #d9d9d9;
	border: 1px solid transparent;
	border-radius: 35px;

	font-size: 16px;
	font-weight: 500;
	line-height: 1.2;
	letter-spacing: 0.01rem;
	text-align: center;
	text-decoration: none;

	-webkit-appearance: none;
	touch-action: manipulation;
	-webkit-tap-highlight-color: transparent;
	user-select: none;
	overflow: hidden;
	transition: background-color 0.2s ease-out, color 0.1s ease-out;

	&:hover {
		opacity: 0.9;
	}

	&--primary {
		background-color: $primary;
		color: white;
	}
	&--link {
		background: transparent;
		background-color: transparent;
		padding-left: 10px;
		padding-right: 10px;
		min-height: 30px;
		height: 30px;
	}

	& + .btn {
		margin-left: 10px;
	}

	&.btn--no-text {
		min-width: 36px;
		padding: 8px 10px;
		border-radius: 50%;
		width: 36px;
		height: 36px;
		min-height: 36px;
		line-height: 1;
		text-align: center;
		justify-content: center;
		background: transparent;

		.icon {
			font-weight: normal;
		}
	}
}

input[type="submit"].btn--link.add-participants{
	background-color: transparent;
	background: transparent;
	padding-left: 10px;
	padding-right: 10px;
	min-height: 30px;
	height: 30px;
}

.btn-group-column {
	display: flex;
	flex-direction: column;

	.btn {
		margin: 10px 0;
	}
}

// icons
.material-icons {
	&.main {
		color: $main;
	}
	&.primary {
		color: $primary;
	}
	&.light-gray {
		color: #b5bcc4;
	}
}

// helper
.border-bottom {
	border-bottom: 1px solid #dee3e9;
}
.align-right {
	text-align: right;
}

.flex-column {
	flex-direction: column !important;
}

.locked-timezone {
	font-size: 14px;
	display: flex;
	align-items: center;
	color: $color-dark;
	text-align: left;
	padding: 5px 20px;
	border-radius: 5px;
	margin: 5px 0;
	backdrop-filter: blur(3px);
	background-color: rgba(white, 0.1);
	border: 1px solid rgba(white, 0.01);
}

.event-description {
	font-size: 13px;
	align-content: center;
	white-space: pre-line;
}

.Select-clear-zone {
	display: none !important;
}

.Select--single {
	flex: 1;
	max-width: 300px;

	.Select-control {
		border-radius: 5px;
		border: 1px solid #EEEEEE;
	}

	.Select-arrow-zone {
		width: 40px;
		padding-right: 12px;
	}
}

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
	padding-right: 50px;
	padding-left: 18px;
}

.Select-value .Select-value-label{
	font-size: 14px;
}

#cancellation-policy-popup-open {
	cursor: pointer;
	font-weight: 500;
	&:hover {
		text-decoration: underline;
	}
}

.d-flex {
	display: flex !important;
}
.d-none {
	display: none !important;
}

.payment-systems.locations{
	.payment-systems__list{
		gap: 12px;
	}
}

.locations .custom-switch__item{
	margin: 0;
	min-width: 120px;

	.custom-switch__item-content{
		gap: 8px;
		flex-wrap: nowrap;

		.custom-switch__name{
			display: block;
			width: auto;
		}
	}
	&.ps_request{
		order: 8;
	}
	&.ps_custom{
		width: auto;
		order: 10;
	}
	&.ps_custom,
	&.ps_request {
		.custom-switch__item-content {
			.custom-switch__icon {
				display: none;
			}
		}
	}

	&.ps_custom{
		.custom-switch__item-content{
			text-align: left;
		}
	}
}

.form-group .payment-systems .custom-switch__item{margin-bottom: 35px;}

.form-group .payment-systems .custom-switch__caption{
	display: block;
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	right: 0;
	padding: 0;
	font-size: 13px;
	font-weight: bold;
	font-family: $titleFont;
	line-height: 1.16;
	overflow: hidden;
	width: 96%;
	margin: 0 auto;
	text-transform: capitalize;
}

.form-group .locations {
	.custom-switch__caption{
		display: block;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.3;
		width: auto;
	}
}


//for APP version
.--app-version{
	.event-block--step.event-block--step-form{
		padding-top: 22px;
	}
}

// ADA styles

$ada-text-size: 16px;
$ada-title: 18px;

$ada-color-neutral-dark: #000;
$ada-color-neutral: #222;
$ada-primary: #0c1ccc;
$ada-primary-focus: #04083c;
$ada-border-color: #ddd;
$ada-secondary-color: #ebebeb;


html body.--ada-styles{
	color: $ada-color-neutral !important;
	background: #fff !important;
	font-size: $ada-text-size;

	.locked-timezone,
	.form-group label,
	.details-list__item-col,
	.dropdown-toggle,
	.multiple-meeting--block_title,
	.event-description,
	a.small{
		font-size: $ada-text-size;
	}

	.react-select__control{
		border-color: $ada-color-neutral !important;
	}

	.event-flexible-duration .react-select__control,
	.event-header .react-select__control,
	.react-select__control,
	.form-group .one-row .custom-checkbox,
	.form-control{
		border-color: $ada-color-neutral;
	}

	section.header {
		background-color: #fff !important;
		color: $ada-color-neutral;
		border-color: $ada-border-color;
	}

	#scheduling .theme_styles--header_text{
		color: $ada-color-neutral-dark;
	}

	.custom-date-field .react-datepicker-wrapper .react-datepicker__input-container i,
	ul.event-list .event-list-item--descr-trigger,
	.user-details--social-links .social-links--item{
		color: #fff;
		border-color: $ada-primary;
		background: $ada-primary;

		svg{
			path{
				fill: #fff;
			}
		}

		&:hover, &:focus{
			background: $ada-primary-focus;
			color: #fff;

			svg{
				path{
					fill: #fff;
				}
			}
		}
	}

	ul.event-list .event-list-item--title {
		color: $ada-primary ;
		font-size: $ada-title;
	}

	ul.event-list .event-list-item--price,
	ul.event-list .event-list-item--body,
	ul.event-list .event-list-item--additional-info,
	ul.event-list .event-list-item--duration,{
		font-size: $ada-text-size;
	}

	ul.event-list .event-list-item--additional-info.--hide-on-mob,
	ul.event-list .event-list-item--duration,
	.user-name,
	ul.event-list .event-list-item:hover .event-list-item--title{
		color: $ada-primary-focus;
	}

	.new-event--confirmed .confirmed-message p,
	.form-group .form-error,
	ul.event-list .event-list-item:hover
	.event-list-item--title,
	.step-title,
	.user-name{
		font-size: $ada-title;
	}
	.user-details p,
	.header__organization-message,
	.date__day{
		font-size: $ada-text-size;
		color: $ada-color-neutral;
	}

	.tag-item {
		color: $ada-primary;
		border: 2px solid $ada-primary;
		font-size: $ada-text-size;

		.tag-item__name{
			font-size: $ada-text-size;
			opacity: 1;
		}
		.tag-item__color{
			height: 16px;
			width: 16px;
			min-width: 16px;
			border: 1px solid #fff;
		}

		&:hover{
			color: #fff;
			background: $ada-primary;
		}

		&.enabled{
			background: $ada-primary-focus;
			color: #fff;
		}
	}

	ul.event-list .event-list-item--descr-trigger {
		width: 36px;
		height: 36px;
	}
	ul.event-list .event-list-item .collapse .event-list-item--content > * {
		font-size: $ada-text-size !important;
		line-height: 28px !important;
	}

	.theme_styles--link, .booking-page__main .theme_styles--link, #scheduling .theme_styles--link,
	#widget-cookies-overlay .theme_styles--link{
		color: $ada-primary;

		&:hover{
			color: $ada-primary-focus;
		}
	}

	// calendar
	#scheduling .theme_styles--tile{
		color: $ada-color-neutral-dark;
	}


	#scheduling .theme_styles--button,
	.cookies .theme_styles--button,
	#widget-cookies-overlay .theme_styles--button{
		background: $ada-primary;
		color: #fff;

		&:hover, &:focus{
			background: $ada-primary-focus;
			color: #fff;
		}
	}
	#scheduling .calendar__item .calendar__multiple-select{
		color: $ada-primary-focus;
		border-color: $ada-primary;
	}

	#scheduling .theme_styles--button,
	.cookies .theme_styles--button,
	#widget-cookies-overlay .theme_styles--button{
		background: $ada-primary;
		color: #fff;
	}

	#scheduling .calendar__item.calendar__item--multiple-selected,
	#scheduling .calendar__item.calendar__item--multiple-selected:before{
		border-color: $ada-primary-focus;
	}
	#scheduling .calendar__item.calendar__item--multiple-selected:before{
		background: $ada-primary;
	}

	#scheduling .calendar__item.calendar__item--active.calendar__item--today .theme_styles--tile {
		color: #fff;
	}

	.share-event--time-item,
	.multiple-meeting--slots-list__item{
		border-color: $ada-primary-focus !important;
		color: $ada-primary !important;
	}
	.multiple-meeting--slots-list__item-remove svg path{
		fill: red;
	}

	// final step
	.details-list__item-col--title{
		opacity: 1;
		color: $ada-color-neutral;
	}
	.details-list__item-col--value{
		color: $ada-color-neutral-dark;
	}
	.details-list__item--name .details-list__item-col--value .service-color {
		bottom: -8px;
	}

	.form-group .custom-switch__item-content{
		border: 1px solid $ada-primary;
	}

	.form-group .custom-switch input[type="radio"]:checked + .custom-switch__item-content:after{
		border-color: $ada-primary-focus;
	}
}


// for booking pages on directory
@import "directory-theme";

@import "rtl-styles";