// List - Component
.dark-mode{
	.list {
		&--checked{
			.list-item {
				&:before{
					color: $color-primary--dark;
				}
			}
		}
	}

	// List Item - Component
	.list-item {
		&--border-bottom {
			border-color: $color-border--dark;
		}
	}


	// steps views
	.list{
		&--action-steps{
			.list-item{
				&:after{
					background: $color-border--dark;
				}

				&__icon{
					border-color: $color-border--dark;
				}

				&.--active{
					.list-item{
						&__icon{
							color: $white;
							background: $color-neutral-dark;
						}
					}
				}

				&.--passed{
					.list-item{
						&__icon{
							color: $white;
							background: $color-success--dark;
						}
					}
				}
			}
		}

		&--guideline-steps{
			.list-item{
				.guideline-step-num{
					border-color: $color-border--dark;
				}
			}
		}
	}
}


.list {
	padding: 0;
	margin: 0;
	text-align: left;

	// Styling
	&--checked {
		.list-item {
			padding-left: 15px;
		}
	}

	&--checked{
		.list-item {
			&:before{
				position: absolute;
				left: 10px;
				top: 2px;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				font-family: "Font Awesome 6 Pro";
				font-weight: 400;
				color: $color-primary;
				content: '\f00c';
			}
		}
	}

	// Types
	&--columns {
		.list-item {
			margin-bottom: 4px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.list-item__caption,
		.list-item__info {
			width: 100%;
			max-width: 50%;
		}
	}

	&--rows {
		display: grid;
		grid-gap: 8px;

		.list-item {
			flex-direction: column;
			gap: 0;
		}

		.list-item__caption,
		.list-item__info {
			width: 100%;
		}
	}

	&--inline{
		.list-item {
			display: flex;
			flex-wrap: wrap;
			gap: 4px 10px;
			align-items: baseline;
		}

		.list-item__info,
		.list-item__info > * {
			display: inline;
		}

		.list-item__info > * {
			display: inline;

			&.line-clamp{
				display: -webkit-box !important;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

		.list-item__caption {
			flex: unset;
			display: inline-flex;
			padding-right: 2px;
		}

		.list-item--heading{
			.list-item__caption{flex: 1;}
		}

		.list-item__caption:after {
			content: ':';
		}
	}

	&--inline-items{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 4px 20px;

		.list-item {
			width: auto;
			display: flex;
			flex-wrap: wrap;
			gap: 4px 10px;
			align-items: baseline;
		}

		.list-item__info,
		.list-item__info > * {
			display: inline;

			&.line-clamp{
				display: -webkit-box !important;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

		.list-item__info > * {
			display: inline;

			&.line-clamp{
				display: -webkit-box !important;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

		.list-item__caption {
			flex: unset;
			display: inline-flex;
			padding-right: 2px;
			&:empty{
				display: none;
			}
		}

		.list-item__caption:after {
			content: ':';
		}
	}
}

// List Item - Component
.list-item {
	position: relative;
	display: flex;
	align-items: flex-end;
	gap: 10px;

	&--heading {
		margin-bottom: 6px;
		font-size: $main-fs;
		font-weight: $semibold;
		align-items: center;
	}

	&--border-bottom {
		padding-bottom: 8px;
		border-bottom: 1px solid $border-color;
	}

	&--with-icon {
		display: flex;
		align-items: baseline;
		gap: 8px;
		margin-bottom: 3px;

		.list-item__icon{
			position: relative;
			top: 0.07em;
			text-align: center;
			min-width: 24px;
			width: 24px;
			margin-right: -4px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__caption {
		flex: 1;
		&-feet-by-content{
			flex: unset;
			padding-right: 4px;
			&:after {content: ':';}
		}
	}

	&__caption-hint {
		margin-right: 15px;
	}
}


// steps views
.list{
	&--action-steps{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px 10px;

		.list-item{
			position: relative;
			margin: 0;
			padding: 50px 0 0 0;
			flex: 1;
			text-align: center;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;

			&:after{
				position: absolute;
				width: calc(100% - 60px);
				top: 20px;
				left: calc(50% + 35px);
				content: '';
				display: block;
				height: 1px;
				background: $border-color;
			}


			&:last-child{
				&:after{display: none;}
			}

			&__caption{opacity: 0.78;}

			&__info{display: none;}

			&__icon{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				min-width: 40px;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 1px solid $border-color;
			}

			&.--active{
				.list-item{
					&__icon{
						color: $white;
						background: $color-neutral-dark;
					}
					&__caption{opacity: 1;}
				}
			}

			&.--passed{
				.list-item{
					&__icon{
						color: $white;
						background: $color-success;

						&:before{content: '\f00c';}
					}
				}
			}
		}
	}

	&--guideline-steps{
		display: flex;
		flex-direction: column;
		gap: 20px;

		.list-item{
			width: 100%;
			position: relative;
			flex-direction: column;
			align-items: flex-start;
			min-height: 40px;
			padding-left: 50px;
			gap: 4px;

			.guideline-step-num{
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				min-width: 40px;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 1px solid $border-color;
			}
		}
	}
}