@import './scheduling/reset.scss';
@import "../../node_modules/react-intl-tel-input/dist/main.css";
@import "ui-kit--sm-meet/font-utils";
@import './scheduling/main.scss';

.intl-tel-input {
  width: 100%;
}

.selected-flag {
  padding-left: 5px;
}

.selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.intl-tel-input .flag-container .arrow {
  position: absolute;
  top: 25%;
  right: 6px;
}

.Select-control {
    border: 0px;

    .Select-placeholder {
      color: #5bb9f4
    }
}

.sidebar-heading {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  padding-right: 8px;
}

.rich-text {
  ol, ul {
    margin-top: 0;  
  }
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.StripeElement {
  height: 44px;
  padding-top: 11px;
  padding-left: 10px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.back-to-admin{
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  padding-bottom: 20px;

  &__organisation{
    width: 100%;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn--back{
    background: rgba(#fff, 0.9);
    color: $color-blue;
    border-radius: 25px;
    min-height: 32px;
    height: auto;
    padding: 4px 25px;
    border: 1px solid #E7ECF7;
    font-weight: normal;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-decoration: none;

    .btn--icon{
      display: none;
      position: relative;
      //display: inline-block;
      bottom: -1px;
      line-height: 1;
    }
  }
}