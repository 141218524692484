.dark-mode{
	.dropdown{
		hr{
			background-color: $color-border--dark;
		}

		&-menu{
			color: $color-second-text-color--dark;
			background-color: $body-bg--dark;
			@include shadowDarkMode;

			@include break-max($mob){
				border-color: $color-primary--dark;
			}

			> svg.icon,
			i {
				color: $color-icons;

				&.zmdi-delete{
					color: $color-danger--dark;
					&:before{
						color: $color-danger--dark;
					}
				}
			}

			a:not([href]):not([tabindex]),
			.btn{
				color: $color-second-text-color--dark;
				background: transparent !important;

				&:hover{
					background: $second-brand-color--dark !important;
					color: $color-neutral--dark ;
				}

				&.btn--link-danger,
				&.btn--danger,
				&.btn--danger-high{
					color: $color-danger--dark;

					.icon{
						color: $color-danger--dark;
					}
				}

				&.btn--link-secondary,
				&.btn--secondary,
				&.btn--secondary-high{
					color: $color-warning;

					.icon{
						color: $color-warning;
					}
				}

				.icon.text-success + .btn__txt{
					color: darken($color-success, 22%);
				}

				&.btn--accented{
					.icon{
						color: darken($color-success, 22%);
					}
				}
			}

			.p{
				background: transparent;
			}
		}

		&-item{
			color: $color-second-text-color--dark;
			background: transparent;

			> .btn{
				background: transparent ;
			}

			&.grid{
				.btn{
					&.btn--no-text{
						&:hover{
							background: darken($second-brand-color--dark, 5%) !important;
							border-color: $second-brand-color--dark !important;
							color: $color-second-text-color--dark !important;

							.icon{
								color: $color-second-text-color--dark !important;
							}
						}
					}

					&:hover{
						background: transparent !important;
						color: $color-neutral--dark;
					}
				}
			}

			&:hover{
				background: $second-brand-color--dark !important;
				color: $color-neutral--dark;
			}
		}

		&-header{
			color: $color-neutral--dark;
		}

		&__mob-bar,
		&__mobile-bar{
			.dropdown__mob-cover{
				background: rgba($body-bg--dark, 0.5);
			}
		}

		&.--to-mob-on__mob-xl{
			.dropdown{
				&-menu{
					@include break-max($mob--xl){
						border-color: 2px $color-primary--dark;
					}
				}
			}
		}
	}
}

.dropdown{
	position: relative;

	.icon.fa-ellipsis-v{
		font-size: 20px;
	}

	hr{
		height: 1px;
		background-color: $border-color;
		border: none;
	}

	&-menu{
		position: absolute;
		top: 100%;
		left: 0;
		display: none;
		float: left;
		min-width: 140px;
		min-width: 11rem;
		padding: 16px 0;
		margin: 0.125rem 0 0;
		font-size: 14px;
		color: $color-neutral;
		text-align: left;
		list-style: none;
		background-color: #fff;
		background-clip: padding-box;
		border: none;
		border-radius: 5px;
		box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
		z-index: 1000;
		&:before{
			content: ' ';
			display: block;
			position: absolute;
			height: 10px;
			width: 100%;
			background: transparent;
			top: -6px;
			left: 0;
			right: 0;
		}
		@include break-min($mob){
			white-space: nowrap;
		}

		&.show {
			display: block;
		}
		&.right{
			@include break-min($mob + 1){
				left: auto !important;
				right: 0 !important;
				transform: none !important;
				top: 40px !important;
			}
		}
		&.left{
			@include break-min($mob + 1){
				left: 0 !important;
				right: auto !important;
				transform: none !important;
				top: 40px !important;
			}
		}
		&.top_right{
			@include break-min($mob + 1){
				top: auto !important;
				left: auto !important;
				right: 0 !important;
				transform: none !important;
				bottom: 40px !important;
			}
		}
		&.top_left{
			@include break-min($mob + 1){
				top: auto !important;
				left: 0 !important;
				right: auto !important;
				transform: none !important;
				bottom: 40px !important;
			}
		}
		@include break-max($mob){
			transform: translateY(33%) !important;
			position: fixed !important;
			left: 0 !important;
			right: 0!important;
			top: auto !important;
			bottom: 0;
			opacity: 0;
			width: 100%;
			z-index: 1060;
			transition: all 0.3s ease-out !important;
			border-top: 2px solid $color-primary;
			border-radius: 0;
			padding: 20px 0 46px;
			&.show {
				opacity: 1;
				transform: translateY(0) !important;
			}
		}

		> svg.icon,
		i {
			//display: inline-block !important;
			//min-width: 25px;
			//text-align: center;
			//font-size: 16px;
			color: $color-icons;
			font-family: "Font Awesome 6 Pro";
			//@include break-min($mob){
			//	font-size: 13px;
			//}

			&:before{
				font-family: "Font Awesome 6 Pro";
			}

			&.zmdi-edit{
				&:before{
					content: '\f303';
				}
			}
			&.zmdi-copy{
				&:before{
					content: '\f24d';
				}
			}
			&.zmdi-delete{
				color: $color-red;
				&:before{
					content: '\f2ed';
					color: $color-red;
				}
			}
			&.zmdi-minus-circle-outline{
				&:before{
					content: '\f00d';
				}
			}
			&.zmdi-time-restore{
				&:before{
					content: '\f3e5';
				}
			}
			&.zmdi-eye{
				&:before{
					content: '\f06e';
				}
			}
			&.zmdi-accounts-add{
				&:before{
					content: '\f067';
				}
			}
			&.zmdi-file-text{
				&:before{
					content: '\f4a6';
				}
			}
		}

		a:not([href]):not([tabindex]),
		.btn{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			min-height: 40px;
			height: auto;
			padding: 6px 25px;
			color: $color-neutral;
			background: transparent !important;
			line-height: 1.2;
			border-radius: 0;
			margin: 4px 0;
			border: none;
			font-size: 14px;
			font-weight: $normal;
			text-align: left;

			@include break-min($mob){
				min-height: 34px;
				margin: 0;
			}

			.btn__txt{
				position: static;
				min-height: unset;
				height: unset;
				width: unset;
				min-width: unset;
				display: inline;
				padding: unset;
				margin: unset;
				background: unset;
				box-shadow: unset;
				font-size: inherit;
				opacity: unset;
				visibility: unset;
				line-height: inherit;

				&:before, &:after{display: none;}
			}

			> svg.icon {
				color: $color-icons;
			}

			.icon{
				display: inline-block;
				text-align: center;
				min-width: 22px;
			}
			&:hover{
				background: $color-secondary-50 !important;
				color: $color-neutral-dark;
			}

			&.btn--action,
			&.btn--action-primary{
				.btn__txt{
					display: inline;
				}
			}

			&.btn--link-danger,
			&.btn--danger,
			&.btn--danger-high{
				color: $color-danger;

				.icon{
					color: $color-danger;
				}
			}

			&.btn--link-secondary,
			&.btn--secondary,
			&.btn--secondary-high{
				color: $color-warning;

				.icon{
					color: $color-warning;
				}
			}

			.icon.text-success + .btn__txt{
				color: $color-success;
			}

			&.btn--accented{
				.icon{
					color: $color-success;
				}
			}

			&.btn--link-primary{
				text-decoration: none;
				&:hover, &:focus, &:active{
					text-decoration: none;
				}
			}
		}

		.p{
			width: 100%;
			padding: 0 25px;
			background: transparent;
			line-height: 1.46;
			border-radius: 0;
			margin: 4px 0;
			border: none;
			font-size: 14px;
		}
	}

	&-item{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-height: 40px;
		padding: 6px 25px;
		color: $color-neutral;
		background: transparent;
		line-height: 1.2;
		gap: 4px 8px;

		.icon{
			display: inline-block;
			text-align: center;
			min-width: 22px;
		}

		&.grid{
			flex-wrap: nowrap;
			margin-top: 0;
			margin-bottom: 0;

			.btn--link{
				flex: 1;
			}

			.btn{
				cursor: pointer;
				padding: 0;
				justify-content: flex-start;

				&.btn--no-text{
					justify-content: center;
					width: 36px;
					border-radius: 50%;
					.icon{
						margin: 0;
					}
					&:hover{
						background: darken($secondary-brand-color, 5%) !important;
						border-color: 1px solid $secondary-brand-color !important;
						color: $color-dark !important;

						.icon{
							color: $color-dark !important;
						}
					}
				}
			}
		}

		&:hover{
			background: $color-secondary-50;
			color: $color-neutral-dark;
		}
	}

	&-header{
		line-height: 1.2;
		font-weight: 600;
		color: $color-neutral-dark;
		font-size: 16px;
		font-family: $titleFont;
		padding: 0px 25px;
	}

	&__mob-bar,
	&__mobile-bar{
		.dropdown__mob-cover{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($color-neutral-dark, 0.5);
			z-index: 1050;
			display: none;
		}
		&.show{
			.dropdown__mob-cover{
				@include break-max($mob){
					display: block;
				}
			}
		}
	}

	&.show{
		.dropdown-menu{
			@include break-max($mob){
				opacity: 1;
				transform: translateY(0) !important;
			}
		}
	}

	&.--to-mob-on__mob-xl{
		&.dropdown__mob-bar,
		&.dropdown__mobile-bar{
			&.show{
				.dropdown__mob-cover{
					@include break-max($mob--xl){
						display: block;
					}
				}
			}
		}

		.dropdown{
			&-menu{
				@include break-min($mob--xl){
					white-space: nowrap;
				}
				&.right{
					@include break-min($mob--xl + 1){
						left: auto !important;
						right: 0 !important;
						transform: none !important;
						top: 40px !important;
					}
				}
				&.left{
					@include break-min($mob--xl + 1){
						left: 0 !important;
						right: auto !important;
						transform: none !important;
						top: 40px !important;
					}
				}
				&.top_right{
					@include break-min($mob--xl + 1){
						top: auto !important;
						left: auto !important;
						right: 0 !important;
						transform: none !important;
						bottom: 40px !important;
					}
				}
				&.top_left{
					@include break-min($mob--xl + 1){
						top: auto !important;
						left: 0 !important;
						right: auto !important;
						transform: none !important;
						bottom: 40px !important;
					}
				}
				@include break-max($mob--xl){
					transform: translateY(33%) !important;
					position: fixed !important;
					left: 0 !important;
					right: 0!important;
					top: auto !important;
					bottom: 0;
					opacity: 0;
					width: 100%;
					z-index: 1060;
					transition: all 0.3s ease-out !important;
					border-top: 2px solid $color-primary;
					border-radius: 0;
					padding: 20px 0 46px;
					&.show {
						opacity: 1;
						transform: translateY(0) !important;
					}
				}

				a:not([href]):not([tabindex]),
				.btn{
					@include break-min($mob--xl){
						min-height: 30px;
						margin: 0;
					}
				}
			}
		}

		&.show{
			.dropdown-menu{
				@include break-max($mob--xl){
					opacity: 1 !important;
					transform: translateY(0) !important;
				}
			}
		}
	}
}