.btn-bar{
	&__row{
		display: flex;
		gap: 6px;

		.btn{
			margin: 0;
		}
	}
	&--content-center{
		.btn-bar__row{
			justify-content: center;
		}
	}
	&--content-right{
		.btn-bar__row{
			justify-content: flex-end;
		}
	}
	&--center{
		.btn-bar__row{
			align-items: center;
		}
	}
	&--wrap{
		.btn-bar__row{
			flex-wrap: wrap;
		}
	}
	&--column{
		.btn-bar__row{
			flex-direction: column;
		}
		&.btn-bar--content-right{
			.btn-bar__row{
				align-items: flex-end;
			}
		}
	}
}

.btn-bar__adaptive-wrapper{
	flex: 1;
	width: 100%;
	display: block !important;
	.btn-bar__adaptive{
		width: 100%;
		display: block !important;
	}
	.btn-bar__row{
		width: 100%;
		justify-content: flex-end !important;
		display: flex;
		gap: 2px 10px;
		margin-left: 0;
		margin-right: 0;

		.btn{
			margin: 0;
			white-space: nowrap;
		}

		.button-submenu{
			order: 10;
		}
	}
}

.buttonContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: nowrap;
	gap: 6px;

	.buttonSubmenu{order: 20;}

	.btn{
		overflow: visible;
		white-space: nowrap;
	}
}