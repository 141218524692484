.grid{
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	+ .grid{
		margin-top: 34px;

		&.mt-n{
			margin-top: 0;
		}
	}

	&.grid-x{
		&-gap-4 {column-gap: 4px;}
		&-gap-8 {column-gap: 8px;}
		&-gap-16{column-gap: 16px;}
		&-gap-24{column-gap: 24px;}
		&-gap-48{column-gap: 48px;}
	}

	&.grid-y{
		&-gap-4 {row-gap: 4px;}
		&-gap-8 {row-gap: 8px;}
		&-gap-16{row-gap: 16px;}
		&-gap-24{row-gap: 24px;}
		&-gap-48{row-gap: 48px;}
	}

	&.align{
		&--baseline{align-items: baseline;}
		&--bottom{align-items: flex-end;}
		&--center{align-items: center}
		&--start{align-items: start}
		&--stretch{align-items: stretch}
	}

	&.justify{
		&--start{justify-content: start;}
		&--center{justify-content: center;}
		&--end{justify-content: end}
		&--space-between{justify-content: space-between}
	}

	&.direction{
		&--row-reverse {
			flex-direction: row-reverse;
		}
		&--row{
			flex-direction: row;
			> .grid-item{width: unset;}
		}
		&--row-reverse{
			flex-direction: row-reverse;
			> .grid-item{width: unset;}
		}
		&--column{
			flex-direction: column;
			align-items: stretch;
			&.align--start{align-items: flex-start}
			> .grid-item{width: 100%;}
		}

		// breakpoint
		&--row{
			&.to-col-on-mob_xs{
				@include break-max($screenMobS){
					flex-direction: column;
					align-items: stretch;

					.grid-item{
						max-width: 100% !important;
					}
				}
			}
			&.to-col-on-mob_m{
				@include break-max($screenMobM){
					flex-direction: column;
					align-items: stretch;

					.grid-item{
						max-width: 100% !important;
					}
				}
			}
			&.to-col-on-mob{
				@include break-max($screenMobL){
					flex-direction: column;
					align-items: stretch;

					.grid-item{
						max-width: 100% !important;
					}
				}
			}
			&.to-col-on-tab{
				@include break-max($screenTabM){
					flex-direction: column;
					align-items: stretch;

					.grid-item{
						max-width: 100% !important;
					}
				}
			}
			&.to-col-on-tab-l{
				@include break-max($screenTabL){
					flex-direction: column;
					align-items: stretch;

					.grid-item{
						max-width: 100% !important;
					}
				}
			}
			&.to-col-on-tab-xl{
				@include break-max($screenTabXL){
					flex-direction: column;
					align-items: stretch;

					.grid-item{
						max-width: 100% !important;
					}
				}
			}
		}
	}

	.grid-item{
		flex: 1;

		&--fit-by-content{
			flex: unset;
			width: auto;
		}
	}

	&.disabled{
		user-select: none;
		pointer-events: none;
		filter: grayscale(50%);
		opacity: 0.5;
		cursor: not-allowed;
	}
}