.scheduling-page--unavailable{
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.calendar__no-slots {
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 420px;

    &-content{
        padding: 16px 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    .theme_styles--header_text{
        font-family: $titleFont;
        font-weight: bold;
        margin: 0;
        padding: 0;
        font-size: 20px;
    }
    .theme_styles--text{
        opacity: 0.8;
    }

    &--image{
        background-image: url('../scheduling--unavailable-time.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 120px;
        width: 120px;
        display: block;
        margin: 0 auto;
        position: relative;
    }
}
.no-data{
    width: 100%;
    max-width: 500px;
    margin: 0  auto;
    padding: 35px 22px;
    background: rgba(#fff, 0.2);
    border-radius: 5px;

    &--image{
        margin-bottom: 20px;
        min-height: 80px;
    }

    &--title{
        margin-bottom: 12px;
    }

    .p{
        max-width: 480px;
        margin-bottom: 5px;
        font-size: 16px;
        color: $color-dark;
        margin-left: auto;
        margin-right: auto;

        &--hint{
            font-size: 14px;
            padding-top: 10px;
            color: $color-grey;
        }
    }

    .btn--info{
        color: #fff;
        background: $color-blue;
        border-color: $color-blue;
        margin: 15px auto;
        min-width: 200px;

        &:hover{
            background: darken($color-blue, 4%);
            border-color: darken($color-blue, 4%);
        }
    }


    &--scheduling-page--unavailable{
        .no-data--image{
            background-image: url('../scheduling--unavailable.svg');
            background-size: 80px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}