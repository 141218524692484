.header{
    position: relative;
    background-color: $second-brand-color;
    border-bottom: 1px solid #dee3e9;
    padding: 10px 0;
    @include md {
        padding: 40px 0;
    }

    &.header--banner-bg{
        background: transparent;
        border-bottom: none;
        min-height: 200px;
        padding-top: 265px;
        padding-bottom: 20px;

        @include break-max(1000px){
            padding-top: 200px;
        }
        @include break-max(680px){
            padding-top: 186px;
        }
        @include break-max(600px){
            padding-top: 150px;
        }
        @include break-max(480px){
            padding-top: 130px;
        }
        @include break-max(440px){
            padding-top: 120px;
        }
        @include break-max(430px){
            padding-top: 110px;
        }
        @include break-max(381px){
            padding-top: 100px;
        }

        .banner-bg{
            // 1400x350 = 248px = 4/1 -- https://aspectratiocalculator.com/
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 990px;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: top center !important;
            //height: 248px;
            aspect-ratio: 4/1;

            &-inner{
                //outline: 1px solid red;
            }

            @include break-max(1000px){
                max-width: 768px;
                aspect-ratio: 4/1;
                //height: 192px;
            }

            &--blur{
                max-width: 100% !important;
                background-size: 110%;
                background-position: top 5% center !important;
                filter: blur(15px);
                min-height: 248px;
                aspect-ratio: unset;
                background-color: #fff;
                opacity: 0.6;

                @include break-max(1000px){
                    min-height: 192px;
                }

                @include break-max(600px){
                    min-height: 140px;
                }
                @include break-max(480px){
                    min-height: 240px;
                    background-color: #fff;
                    opacity: 0.3;
                }
            }
        }

        .wrapper__user-header{
            .user-avatar--image{
                top: -58px;
            }
        }

        .header__organization-wrapper{
            .header__organization-logo{
                position: relative;
                margin-right: auto;
                margin-top: -58px;
            }
        }
    }

    .wrapper{
        &.header__organization-wrapper,
        &.wrapper__user-header{
            position: relative;
        }
    }

    .content{
        margin-top: 0;
        width: 100%;
        margin-bottom: 22px;
        @include md {
            padding-left: 32px;
        }

        &.content--border-left {
            border-top: 1px solid #dee3e9;
            padding-top: 22px;
            @include md {
                padding-top: 0;
                margin-bottom: 0;
                border-top: none;
                border-left: 1px solid #dee3e9;
            }
        }
    }

    .company-logo{
        margin-bottom: 10px;
        @include md {
            margin-bottom: 0;
            margin-left: 30px;
        }

        a{
            display: block;
        }
    }

    &__organization{
        padding-bottom: 25px;

        &-wrapper{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            &.wrapper{
                flex-direction: column;
            }
        }
        &-logo{
            max-height: 80px;
            margin-bottom: 20px;
        }
        &-name{
            margin-bottom: 10px;
        }
        &-message{
            font-size: 14px;
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            color: $color-grey;
        }
    }

    &__has-user-header{
        padding-bottom: 12px;

        .header__organization-wrapper,
        .header__organization-logo{
            display: none;
        }
    }
}

.logo {
    width: 170px;
    height: 42px;
    background-image: url('../sm__logo.svg');
    background-repeat: no-repeat;
    background-size: 170px;
}

.user {
    display: flex;

    &-avatar {
        max-width: 92px;
        height: 72px;
        flex-shrink: 0;
        border-radius: 50%;

        &--image{
            position: relative;
            min-width: 72px;
            margin-right: 20px;
            border-radius: 50%;
            transition: all 0.5s ease;
            @include break-max(480px){
                margin-right: 8px;
            }

            &:hover{
                box-shadow: 0px 0px 30px 1px rgba(#071725, 0.2);
                transform: scale(2.8) translate(30%, 7%);
                @include break-max($mob){
                    transform: scale(2.8) translate(25%, 32%);
                }
            }
        }
    }
    &-details {
        p {
            font-size: 14px;
            color: $color-grey;
            line-height: 1.4;
        }
    }
    &-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 6px;
    }
    &-details--social-links{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px 10px;

        &.social-links__organisation{
            justify-content: center;
        }

        .social-links--item{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            margin-top: 8px;
            padding: 4px;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: rgba(#fff, 0.36);
            border: 1px solid #E7ECF7;
            line-height: 1;
            transition: all 0.2s ease-out;
            &:hover{
                background: rgba(#fff, 0.8);

                svg path{
                    fill: $color-blue;
                }
            }

            .icon{
                display: inline-block;
                line-height: 1;

                svg{
                    height: 16px;
                }
            }

            .social-links--item_txt{
                display: none;
            }
        }
    }
}