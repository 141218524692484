$font-path: '../fonts/';
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold",    600, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Medium",  500, inherit, eot woff2 woff ttf otf svg);

@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Regular",      400, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Light",        300, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-SemiBold",     500, inherit, eot woff2 woff ttf otf svg);

//font styles
$titleFont: "Mulish", sans-serif;
$mainFont: "NotoSans", sans-serif;

// variables
$primary: #5bb9f4;
$main: #0B3052;
$original-schedule-color: #fff999;
$original-schedule-color-hover: darken($original-schedule-color, 20%);
$color-grey: #788291;
$color-dark: #0B3052;
$color-blue: #06ADEF;
$border-color: #E7ECF7;
$color-green: #00D38B;
$second-brand-color: #f1f6ff;
$color-white: #fff;
$color-primary: #06ADEF;
$color-neutral: #788291;
$color-neutral-dark: #0B3052;
$color-secondary: #F1F6FF;
$color-icons: #ABBCCC;

$color-marketing: #FEC439;
// Secondary brand colors
$color-secondary-50: #f8faff; // for some sections/blocks bg
$sm-second-brand-color: #FFF8EA; // secondary SimplyMeet color

$text-fs: 14px;
$text-small-fs: 12px;

// Font weight
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 600;

// breakpoints
// Responsiveness breakpoints
$fullHD: 1920px;
$lap: 1366px;
$tab: 1023px;
$mob: 767px;
$mob-xl: 900px;
$mob-xs: 480px;

//experiment screen sizes
$screenTabXL: 1200px;
$screenTabL: 1023px;
$screenTabM: 800px;
$screenMobL: 768px;
$screenMobS: 370px;

//media query
@mixin break-min($point) {
    @media (min-width: $point) {
        @content;
    }
}

@mixin break-max($point) {
    @media (max-width: $point) {
        @content;
    }
}

// Small devices (landscape phones, 576px and up)
@mixin sm() {
    @media only screen and (min-width: 576px) {
        @content;
    }
}

// Medium devices (tablets, 768px and up)
@mixin md() {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin transition{
    transition: all .25s ease;
}

@mixin scroll-style($size){
    $scrollbar-1-thumb-width: $size;
    $scrollbar-1-thumb-color: $color-neutral;
    $scrollbar-1-track-color: $color-icons;

    // Chrome & Opera
    &::-webkit-scrollbar {
        width: $scrollbar-1-thumb-width;
        height: $scrollbar-1-thumb-width;

        &-track {
            background-clip: content-box;
            border: ($scrollbar-1-thumb-width/4) solid transparent;
        }

        &-thumb {
            background-color: $scrollbar-1-thumb-color;
            cursor: pointer;

            &:hover {
                background-color: mix($scrollbar-1-thumb-color, #000, 90%);
            }
        }
        &-corner,
        &-track {
            background-color: $scrollbar-1-track-color;
        }
    }

    // Firefox
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-1-thumb-color $scrollbar-1-track-color;
}