/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
// Font Awesome core compile (Web Fonts-based)
// -------------------------

@import 'functions';
@import 'variables';
@import 'mixins';
@import 'core';
@import 'sizing';
@import 'fixed-width';
//@import 'list';
//@import 'bordered-pulled';
//@import 'animated';
//@import 'rotated-flipped';
@import 'stacked';
@import 'icons';
@import 'screen-reader';


@import "solid";
@import "regular";
@import "light";
@import "thin";
@import "duotone";
@import "brands";

