.dark-mode{
	.divider {
		&.divider-color-secondary {
			background-color: $color-border--dark;
		}
	}
}

.divider {
	&.divider-orientation-horizontal {
		height: 1px;

		@include break-max($tab) {
			.divider:not(.divider-fixed-margin) {
				margin-block: 32px;
				margin-inline: -20px;
			}
		}
		&.divider-margin-small {
			margin-block: 6px;
		}
		&.divider-margin-medium {
			margin-block: 12px;
		}

		&.divider-margin-large {
			margin-block: 24px;
		}

		&.divider-margin-none {
			margin-block: 0px;
		}

		&.divider-behavior-fit-by-container{
			margin-inline: 0;
			width: 100%;
		}
	}

	&.divider-orientation-vertical {
		width: 1px;

		&.divider-margin-medium {
			margin-inline: 12px;
		}

		&.divider-margin-large {
			margin-inline: 24px;
		}
	}


	&.divider-color-primary {
		background-color: $color-primary;
	}

	&.divider-color-secondary {
		background-color: $border-color;
	}

	&.divider-color-success {
		background-color: $color-success;
	}


	&:not(.divider-desktop-show) { 
		@include break-min($tab) {
			background-color: transparent;
		}
	}
}