.event-block--details-form_poll{
	max-width: 980px;
	margin: 10px auto;
	padding: 100px 18px 22px 18px;

	.event-block--step_poll{
		&-info{
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-wrap: wrap;
			gap: 28px 20px;
			@include break-max($mob){
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.event-block--step-item__sidebar{
		&-poll_bar{
			margin-bottom: 16px;
			.theme_styles--link{
				display: inline-flex;
				align-items: center;
				justify-content: flex-start;
				min-height: 26px;
				gap: 6px;
				padding: 4px 0;
				cursor: pointer;

				.icon{font-size: 18px;}
			}
		}
		&-poll_title{
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 14px;
		}
	}

	.event-block--step-item{
		&__sidebar{
			width: 360px;
			min-width: 320px;
			font-size: 14px;

			.event-block--step-item__sidebar-content{
				padding: 20px;
				border-radius: 6px;
				margin-bottom: 12px;
			}

			.list{
				&-item{
					padding: 2px 0;
					margin-bottom: 8px;
					&:last-child{margin-bottom: 0;}
					.icon{opacity: 0.7;}
				}
			}

			@include break-max($mob){
				width: 100%;
				min-width: unset;
			}
		}
		&__poll-time{
			flex: 1;
			@include break-max($mob){
				width: 100%;
				flex: unset;
			}
		}
	}

	.share-event{
		.dropdown-toggle{
			z-index: 9999;
			width: 300px;
			padding: 10px 0;
			@include break-max($mob){
				min-width: 270px;
			}
			> div{
				width: 100%;
			}
		}

		.react-select{
			&__control{
				padding-right: 4px;
				backdrop-filter: blur(3px);
				background-color: rgba(white, 0.1);
				border: 1px solid rgba(white, 0.01);
				box-shadow: 0px 2px 4px -4px rgba(black, 0.15);
			}
			&__value-container{
				padding-left: 16px;
				padding-right: 12px;
			}
			&__single-value{
				font-family: $mainFont;
			}
			&__input-container{
				padding-top: 1px;
				padding-bottom: 1px;
				input[type='text']{
					height: 28px;
					line-height: 28;
				}
			}
			&__dropdown-indicator{
				color: darken($color-grey, 5%);
				svg{
					height: 16px;
				}
			}
			&__indicator-separator{
				display: none;
			}
		}

		&--header{
			margin-bottom: 12px;
		}
		&--timezone{
			font-size: 13px;
			opacity: 0.75;
			padding-top: 14px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
		}
		&--title{
			font-size: 16px;
			font-weight: bold;
			line-height: 1.1;
		}
		&--wrapper{
			width: 100%;
			padding: 20px;
			border-radius: 6px;
			border: 1px solid $border-color;
			margin-bottom: 36px;
		}
		&--content{
			display: flex;
			flex-direction: column;
			gap: 18px;
		}
		&--time-list-wrapper{
			width: 100%;
		}
		&--time-list{
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: 6px;
			padding-top: 6px;
		}
		&--time-item{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			position: relative;
			margin: 0;
			line-height: 1;
			width: 30%;
			min-width: 100px;
			max-width: 116px;
			box-sizing: border-box;
			text-align: center;
			padding: 9px 6px;
			font-size: 16px;
			font-weight: 400;
			font-family: sans-serif;
			font-style: normal;
			border: 1px solid $color-primary;
			color: $color-primary;
			text-decoration: none;
			background: #fff;
			border-radius: 6px;
			white-space: nowrap;
			cursor: pointer;

			span{
				position: relative;
				z-index: 2;
			}

			&:before{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba($color-primary, 0.1);
				visibility: hidden;
				opacity: 0;
				z-index: 1;
				transition: all 0.25s ease-out;
			}

			&.selected{
				&:before{
					opacity: 0.1;
					visibility: visible;
				}
			}
		}
		&--time-item-count{
			font-size: 12px;

			.icon{
				font-weight: 500;
			}
		}
		&--select-count{
			font-size: 13px;
			opacity: 0.8;
		}
	}

	.dropdown-voted-list{
		padding-left: 16px;

		&__toggler{
			background: transparent;
			padding: 0;
			height: auto;
			min-height: 36px;

			.btn__txt{
				display: none;

				&-html{
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
				}
			}

			.voted-list{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 4px;

				&--item{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 36px;
					min-width: 36px;
					height: 36px;
					position: relative;
					margin-left: -33px;
					border-radius: 50%;
					border: 1px solid $border-color;
					color: #fff;
					background: $color-marketing;
					text-transform: uppercase;
					font-size: 18px;

					&:first-child{
						margin-left: 0;
					}
				}
			}
		}

		.dropdown-menu{
			overflow: hidden;
			padding-left: 18px;
			padding-right: 18px;
			color: $color-dark;

			.dropdown-header{
				padding: 0;
				line-height: 1;
				margin-bottom: 6px;
			}

			&.left{
				.voted-list--users{
					max-height: 180px;
				}
			}

			.voted-list--users{
				overflow-y: auto;
				overflow-x: hidden;
				display: block;
				width: 100%;
				max-height: 104px;
				padding-right: 8px;
				@include scroll-style(2px);
				@include break-min($tab){
					@include scroll-style(6px);
				}
			}

			.dropdown-voted--item{
				display: block;
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-bottom: 4px;
				font-size: 13px;
				&:last-child{margin-bottom: 0;}
				@include break-min($mob){
					max-width: 220px;
				}
			}
		}

		.dropdown__mob-cover{
			&.show{
				@include break-max($mob){display: block;}
			}
		}
	}

	.poll-time--submit-step{
		&_grid{
			width: 100%;
			padding: 24px;
			border-radius: 6px;
			border: 1px solid $border-color;
			margin-bottom: 26px;

			.form-group{
				&:last-child{margin-bottom: 0;}
			}
		}
		&_subtitle{opacity: 0.7; font-size: 14px;}
		&_alert{
			width: 100%;
			max-width: 600px;
			margin: 8px auto;
			padding: 28px 22px;
			border-radius: 6px;
			border: none;//1px solid $border-color;
			box-shadow: 2px 2px 15px 0px rgba(45, 51, 72, 0.10);
			text-align: center;
			&.alert--success{
				.icon{color: $color-green;}
			}

			.heading{
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				margin-bottom: 14px;

				.icon{font-weight: 600;}
			}

			.p{font-size: $text-fs; opacity: 0.7;}
		}
	}
}


// BANNER
.sm-banner--poll{
	position: relative;
	padding: 0;
	margin: 30px auto;
	background: $sm-second-brand-color;
	text-align: center;
	@include break-max($mob){
		padding-top: 10px;
	}

	&:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: -50vw;
		width: 200vw;
		height: 100%;
		background: $sm-second-brand-color;
		z-index: 1;
	}

	.grid{
		position: relative;
		z-index: 2;

		.grid-item{
			padding: 8px 0;

			.pictures{
				padding: 0;
			}
		}

		.btn-bar{
			padding-top: 18px;
		}


		picture{
			display: inline-block;

			img{
				width: auto;
				max-height: 190px;
				max-width: 100%;
			}
		}
	}
}
