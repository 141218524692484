.dark-mode{
    .form-group{
        .custom-switch{
            .sbpay-sub-systems{
                &--list{
                    .sbpay-p{

                    }
                }

                img{

                }
            }

            &__item{
                &-content{
                    background: rgba(#fff, 0.05);
                    box-shadow: 0px 0px 6px rgba(#000, 0.4);

                    &:hover {
                        background: rgba(#fff, 0.1);
                        box-shadow: 0px 0px 10px rgba(#000, 0.6);
                    }
                }

                &:hover{
                    @include break-min(1100px){
                        .sbpay-sub-systems--informer{
                            background: $sidebar-bg--dark;
                            box-shadow: 2px 2px 15px rgba($color-primary--dark, 0.2);
                        }
                    }
                }
            }

            input[type="radio"]:checked + .custom-switch__item-content {
                background: rgba(#fff, 0.04);
                box-shadow: 0px 0px 14px rgba($color-success--dark, 0.4);

                &:after {
                    background: $color-success--dark;
                }
            }
        }
    }
}

.form-group{
    .custom-switch{
        &__title{
            width: 100%;
            display: block;
        }

        &__list{
            padding-top: 6px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 10px 20px;

            .form-error{
                order: 999;
                width: 100%;
            }
        }

        .sbpay-sub-systems{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 4px 5px;
            width: 100%;
            min-height: 31px;
            transition: all 0.3s ease-out;

            &--informer{
                position: relative;
                @include break-min(1100px) {
                    padding: 0 10px;
                    transition: all 0.3s ease-out;
                    background: transparent;
                    left: 0;
                    right: 0;
                    box-shadow: none;
                    margin: 0 auto;
                }
            }

            &--list{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 4px 5px;
                max-width: 100%;
                opacity: 0.5;
                overflow: hidden;
                @include break-min(1100px){
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    max-width: 124px;
                }

                .sbpay-p{
                    order: -100;
                    min-width: 156px;
                    display: none;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    max-height: unset;
                    font-size: 13px;
                    font-weight: normal;
                    position: relative;
                    line-height: 1.2;
                    text-align: left;
                    transition: all 0.2s ease-out;
                    text-wrap: balance;
                    overflow: hidden;
                    @include break-min(1100px){
                        transform: scale(0.3);
                        max-height: 0px;
                    }
                }
            }

            img{
                height: 12px;
                max-width: 32px;

                &.mastercard{order: -8;}
                &.visa{order: -7;}
                &.amex{order: -6;}
                &.jcb{order: -5;}
            }
        }

        &__item{
            position: relative;
            display: flex;
            align-items: stretch;
            padding: 0;
            width: calc(50% - 10px);
            min-height: 50px;
            opacity: 1;

            &-content{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                padding: 12px;
                cursor: pointer;
                background: rgba(#fff, 0.6);
                box-shadow: 0px 0px 6px rgba(45, 51, 72, 0.08);
                transition: all 0.2s ease-out;
                flex-direction: column;

                &:hover {
                    background: rgba(#fff, 0.7);
                    box-shadow: 0px 0px 10px rgba(45, 51, 72, 0.1);
                }
            }

            &.ps_delay {
                order: 20;
                .custom-switch__icon img{
                    //max-width: 80%;
                }
            }

            &.ps_safecharge,
            &.ps_paymentasia{
                .custom-switch__icon img{
                    max-height: 60px;
                    max-width: 120px;
                }
            }

            &.ps_custom{
                .custom-switch__icon img{
                    max-height: 38px;
                }
                .custom-switch__name{
                    display: block;
                    font-size: 12px;
                    padding-top: 4px;
                }
                .custom-switch__item-content {
                    .custom-switch__icon {
                        //display: none;
                    }
                }
            }

            &.ps_request {
                .custom-switch__item-content {
                    .custom-switch__icon {
                        display: none;
                    }
                }
            }

            &:hover{
                @include break-min(1100px){
                    .sbpay-sub-systems--informer{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        position: absolute;
                        top: 0;
                        left: -20px;
                        right: -20px;
                        margin: 0 auto;
                        background: #fff;
                        z-index: 2;
                        border-radius: 5px;
                        box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
                        padding: 8px;
                        text-align: left;

                        .sbpay-p{
                            display: block;
                            transform: scale(1);
                            max-height: 50px;
                            padding-bottom: 4px;
                        }

                        .sbpay-sub-systems--list{
                            max-width: 100%;
                            flex-wrap: wrap;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &__icon{
            display: block;
            text-align: center;
            line-height: 1;
            img{
                display: inline-block;
                width: auto;
                height: auto;
                max-height: 42px;
                max-width: 90px;
            }
        }

        &__toggler{
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
        }

        &__caption{
            //display: none;
            width: 100%;
            font-size: 16px;
            font-weight: normal;
            padding-top: 4px;
            line-height: 1.3;
        }

        input[type="radio"]:checked + .custom-switch__item-content {
            background: rgba(#fff, 0.6);
            box-shadow: 0px 0px 14px rgba(45, 51, 72, 0.2);

            &:after {
                content: ' ';
                display: block;
                width: 96%;
                height: 1px;
                background: $color-green;
                opacity: 0.5;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: 0;
            }
        }
    }

    .custom-switch + textarea{
        margin-top: 12px;
    }
}

