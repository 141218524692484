//font styles
$titleFont: "Mulish", sans-serif;
$mainFont: "NotoSans", sans-serif;
$font-family-icon: 'Font Awesome 6 Pro';
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;

// Fonts size
$h2: 34px;
$h3: 18px;
$main-text: 14px;
$main-fs: 16px;
$input-fz: 16px;
$small-text: 12px;
$modal-title: 18px;

$alert-text: 13px;
$alert-popup-br: 6px;

// Colors
$white: #fff;
$color-dark: #0B3052;
$color-grey: #788291;
$color-blue: #06ADEF;
$color-green: #00D38B;
$color-red: #FF3259;
$color-orange: #FEC439;
$color-purple: #C245E1;
$color-icons: #ABBCCC;
$secondary-brand-color: #F1F6FF;
$secondary-brand-color-darker: #E7ECF7;
$border-color: #E7ECF7;
$border-color-darker: #ABBCCC;
$border-color--focus: #0B3052;
$bg-disabled-status: rgba(196, 196, 196, 0.1);
$accent-bg: #FFF8EA;

$color-white: #fff;
$color-neutral: #788291;
$color-neutral-dark: #0B3052;
$color-neutral-light: #F6F6F6; // for disabled items etc
$color-primary: #06ADEF;
$color-financial: #00D38B;
$color-success: #00D38B;
$color-marketing: #FEC439;
$color-danger: #FF3259;
$color-warning: #FEC439;
$color-icons: #ABBCCC;
$color-time-slots: #D8F4FF;


$lite-green: #E5FBF3;
$lite-red: #FFEAEE;
$lite-gray: #F1F6FF;
$lite-orange: #FFFCF5;

// Secondary brand colors
$color-secondary: #F1F6FF;
$color-secondary-50: #F8FBFF; // for some sections/blocks bg
$sm-second-brand-color: #FFF8EA; // secondary SimplyMeet color

$alert-danger: #FEF4F7;

$disableBtnBg: #E7ECF7;
$disableBtnColor: #ABBCCC;


// Gradient BG
//danger
$danger-gradient: linear-gradient(275deg, #FF2A26 2.46%, #BC0704 97.04%);
//warning
$warning-gradient: linear-gradient(275deg, #F9E50A 2.46%, #FE8141 97.04%);
//success
$success-gradient: linear-gradient(275deg, #13E29B 2.48%, #049D68 94.59%);

// Colors for dark mode --dark
$color-neutral--dark: #f6f6f6;
$color-second-text-color--dark: #bcbcbc;
$color-placeholder--dark: #898989;
$color-danger--dark: #ff6666;
$color-primary--dark: #1d9ed1;
$color-success--dark: darken(#00D38B, 10%);
$color-warning--dark: darken(#FEC439, 12%);
$color-disabled-text--dark: #929292;

$body-bg--dark: #1a1a1a;
$second-brand-color--dark: #282828;
$input-bg--dark: #404040;
$sidebar-bg--dark: #242424;

$color-border--dark: #555555;
$color-border-focus--dark: #777777;
$color-border-table--dark: #303030;

$alert-danger--dark: #3e292d;
$alert-danger-border--dark: #cf6679;

$alert-info--dark: #1a323b;
$alert-info-border--dark: #3a8aaa;

$alert-success--dark: #1f3220;
$alert-success-border--dark: #224324;

$alert-warning--dark: #484421;
$alert-warning-border--dark: #645014;

$user-status-color: #fff;

// Font weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 600;
$extra-bold: 800;
$bold-modal-title: 800;

$br: 5px;
$br-tab-item: 8px;

// Responsiveness breakpoints
$fullHD: 1920px;
$lap: 1366px;
$small-lap: 1200px;
$tab: 1023px;
$mob: 767px;
$mob-xl: 900px;
$mob--xl: 960px;
$mob-xs: 480px;

//experiment screen sizes
$screenTabXL: 1200px;
$screenTabL: 1023px;
$screenTabM: 800px;
$screenMobL: 768px;
$screenMobM: 560px;
$screenMobS: 370px;


//margins
$mb-xxxl: 56px !important;
$mb-xxl: 30px !important;
$mb-xl: 16px !important;
$mb-l: 8px !important;
$mb-m: 4px !important;
$mb-0: !important;
$mb: 0 !important;

//media query
@mixin break-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin break-max($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin gradient--dark-blue {
  background: linear-gradient(139.93deg, #3873CD 18.92%, #0B3052 87.06%);
}

.color--danger {
  color: $color-red !important;
}

@mixin scroll-style($size){
  $scrollbar-1-thumb-width: $size;
  $scrollbar-1-thumb-color: $color-neutral;
  $scrollbar-1-track-color: $color-icons;

  // Chrome & Opera
  &::-webkit-scrollbar {
    width: $scrollbar-1-thumb-width;
    height: $scrollbar-1-thumb-width;

    &-track {
      background-clip: content-box;
      border: ($scrollbar-1-thumb-width/4) solid transparent;
    }

    &-thumb {
      background-color: $scrollbar-1-thumb-color;

      &:hover {
        background-color: mix($scrollbar-1-thumb-color, #000, 90%);
      }
    }
    &-corner,
    &-track {
      background-color: $scrollbar-1-track-color;
    }
  }

  // Firefox
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-1-thumb-color) var(--scrollbar-1-track-color);
  }
}

@mixin scroll-style--dark($size){
  $scrollbar-1-thumb-width: $size;
  $scrollbar-1-thumb-color: $input-bg--dark;
  $scrollbar-1-track-color: $second-brand-color--dark;

  // Chrome & Opera
  &::-webkit-scrollbar {
    width: $scrollbar-1-thumb-width;
    height: $scrollbar-1-thumb-width;
    border-radius: 6px;

    &-track {
      background-clip: content-box;
      border: ($scrollbar-1-thumb-width/4) solid transparent;
    }

    &-thumb {
      background-color: $scrollbar-1-thumb-color;

      &:hover {
        background-color: mix($scrollbar-1-thumb-color, #000, 90%);
      }
    }
    &-corner,
    &-track {
      background-color: $scrollbar-1-track-color;
    }
  }

  // Firefox
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-1-thumb-color $scrollbar-1-track-color;
  }
}


@mixin makeGrey{
  filter: invert(.5) sepia(1) hue-rotate(180deg) saturate(.6) brightness(1);
}

@mixin makeImageSoft{
  filter: grayscale(0.7) invert(88%);
}

@mixin shadowDarkMode{
  box-shadow: 0px 0px 22px -2px rgba(#000, 0.9); //0px 2px 18px rgba(45, 51, 72, 0.48);
}

@mixin shadowWhiteMode{
  box-shadow: 2px 2px 18px rgba(45, 51, 72, 0.12)
}


//External events mixins
//https://10015.io/tools/css-background-pattern-generator == here gradient generator for this styles

//base styles for external events
@mixin externalEventBase {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: $border-color !important;
  background-color: $user-status-color !important;
  background-repeat: repeat-y !important;
  box-sizing: border-box !important;
}

@mixin externalEventBaseDark {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: $color-border-table--dark !important;
  background-color: $input-bg--dark !important;
  background: $input-bg--dark !important;
  background-repeat: repeat-y !important;
  box-sizing: border-box !important;
  color: $color-neutral--dark !important;
}


/* Working elsewhere */
@mixin workElsewhere {
  background-image:
    linear-gradient(135deg, #392ad0 25%, transparent 25%),
    linear-gradient(225deg, #392ad0 25%, transparent 25%),
    linear-gradient(45deg, #392ad0 25%, transparent 25%),
    linear-gradient(315deg, #392ad0 25%, rgba(#fff, 0) 25%) !important;
;
  background-size: 10px 10px !important;
}

/* Free */
@mixin workFree {
  background-image: linear-gradient(to right, $color-success--dark, $color-success--dark 10px, rgba(#fff, 0) 10px, rgba(#fff, 0) ) !important;
  background-size: 10px 100% !important;
  background-position: 0px 0px;
}

/* Tentative */
@mixin workTentative($size){
  background-image: repeating-linear-gradient(-45deg,
    rgba(#577bc1, 1) 0px,
    rgba(#577bc1, 1) $size,
    transparent 0px,
    transparent 50%
  ) !important;
  background-size: 10px 10px !important;
}

/* Busy */
@mixin workBusy {
  background-image: linear-gradient(to right, #587BC0, #587BC0 10px,
                    rgba(#fff, 0) 10px, rgba(#fff, 0) ) !important;
  background-size: 10px 100% !important;
  background-position: 0px 0px;
}

/* Out of office */
@mixin workOof {
  background: repeating-linear-gradient(45deg, rgba($color-danger--dark, 1) 0, rgba($color-danger--dark, 1) 5%, transparent 0, transparent 50%),
  repeating-linear-gradient(135deg, rgba($color-danger--dark, 1) 0, rgba($color-danger--dark, 1) 5%, transparent 0, transparent 50%) !important;
  background-size: 10px 10px !important;
  background-position: 0px 0px;
}

/* Busy other calendars */
@mixin workBusyOther{
  background-image: linear-gradient(to right, #999, #999 10px,
                    rgba(#fff, 0) 10px, rgba(#fff, 0) ) !important;
  background-size: 10px 100% !important;
  background-position: 0px 0px;
}