.cancellation-policy-popup{
	position: fixed;
	display: none;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	background: rgba($color-dark, 0.2);
	z-index: 9999;
	@include break-min($mob){
		padding: 20px;
	}

	&-content{
		display: flex;
		flex-direction: column;
		//max-height: calc(100vh - 70px);
		background: #fff;
		border-radius: 0;
		padding: 10px 0;
		width: 100%;
		margin: 0 auto;
		@include break-min($mob){
			border-radius: 22px;
			max-width: 700px;
		}

	}

	&--header{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 10px 20px;
		gap: 20px;

		.title{
			flex: 1;
			font-size: 16px;
		}
	}

	&--body{
		flex: 1;
		padding: 10px;
		margin-bottom: 10px;

		.scroll-box{
			padding: 0 10px;
			overflow: auto;
			max-height: calc(100vh - 220px);

			.p{
				font-size: 15px;
			}
		}
	}

	&--footer{
		padding: 10px 20px;

		.btn-bar{
			padding: 0;
		}
	}
}